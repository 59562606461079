import { CopyOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { AppContext } from 'App.context';
import AppButton from 'Components/common/Forms/AppButton/AppButton';
import Loading from 'Components/common/Loader/Loading';
import Colors from 'constants/Colors';
import React, { useContext } from 'react';
import { copyTextToClipboard } from 'utils/TextUtils';
import TestResult from '../AutomaticConfigurationTest/TestResult/TestResult';
import useAutomaticBookingTest from './AutomaticBookingTest.hook';
import styles from './AutomaticBookingTest.styles';
import BookingTestInput from './BookingTestInput/BookingTestInput';

export default function AutomaticBookingTest({
  icon,
  serviceName,
  buttonText,
  buttonAction,
  testResult,
  description,
  isTesting,
  inputs,
}) {
  const {
    appActions: { translate },
  } = useContext(AppContext);
  const { state, actions } = useAutomaticBookingTest(inputs);
  const errorMsg = `${testResult?.message} \n${
    testResult?.data?.response?.stack ? testResult?.data?.response?.stack : ''
  }`;
  const log = testResult ? (testResult.success ? testResult?.message || 'Ok' : errorMsg) : null;

  return (
    <div style={styles.container}>
      <div style={{ ...styles.buttonContainer, width: 200 }}>
        <div style={styles.title}>{buttonText}</div>
        <div style={styles.description}>{description}</div>
        {state.inputs &&
          state.inputs.length &&
          state.inputs.map(input => {
            return (
              <BookingTestInput
                key={input.key}
                id={input.key}
                title={translate(`checkinConfig.${input.key}`)}
                type={input.type}
                value={actions.getInputValue(input.key)}
                onValueChanged={actions.setInputValue}
              />
            );
          })}
        <div style={{ height: 8 }} />
        <AppButton text={buttonText} action={() => buttonAction(actions.getBookingData())} />
      </div>

      <div style={{ width: '100%', gap: 8, flexDirection: 'column' }}>
        <div style={{ width: '100%' }}>
          {isTesting ? (
            <div style={{ display: 'flex', flexDirection: 'row', gap: 16 }}>
              <Loading size={'small'} /> Realizando el check-in online...
            </div>
          ) : testResult != null ? (
            <div style={{ display: 'flex', flexDirection: 'row', gap: 16 }}>
              <TestResult success={testResult?.success} />
            </div>
          ) : null}
        </div>
        {testResult ? (
          <div style={styles.logBox}>
            <pre>{log}</pre>
            {testResult && !testResult?.success && (
              <div style={styles.copyButton}>
                <Button
                  type="primary"
                  shape="square"
                  ghost
                  style={{ color: Colors.superUserButton, borderColor: Colors.lightGray }}
                  icon={<CopyOutlined />}
                  onClick={() => copyTextToClipboard(log)}
                />
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
}
