import { EyeOutlined, MailOutlined } from '@ant-design/icons';
import React, { useContext } from 'react';
import Space from '../../Space/Space';
import WelcomeButton from '../WelcomeLayout/WelcomeButton/WelcomeButton';
import WelcomeInput from '../WelcomeLayout/WelcomeInput/WelcomeInput';
import WelcomeLayout from '../WelcomeLayout/WelcomeLayout';
import Loading from '../../../common/Loader/Loading';
import { AppContext } from '../../../../App.context';
import { Checkbox } from 'antd';
import useRegisterPaperlessHook from './RegisterPaperless.hook';
import styles from './RegisterPaperless.styles';
import constants from 'utils/constants';

export default function RegisterPaperless() {
  const params = new URLSearchParams(window.location.search);
  const IH_CLIENT = params.get('ih_client');

  const { state, actions } = useRegisterPaperlessHook();
  const {
    appState: { currentLanguage },
    appActions: { translate },
  } = useContext(AppContext);

  const headerLogo =
    IH_CLIENT == constants.IH_CLIENT.RECEPTION
      ? require('../../../../assets/images/logoPaperless.png')
      : require('../../../../assets/images/logoKiosk.png');

  const bgImage =
    IH_CLIENT == constants.IH_CLIENT.RECEPTION
      ? require('../../../../assets/images/welcomeBackground.png')
      : require('../../../../assets/images/kioskBg.jpg');

  return (
    <WelcomeLayout
      title={translate('registerPaperless.title')}
      background={bgImage}
      headerLogo={headerLogo}
    >
      <WelcomeInput
        icon={<MailOutlined />}
        value={state.email}
        placeholder={translate('login.emailPlaceholder')}
        onChange={value => {
          actions.setField('email', value);
        }}
      />
      <Space height={16} />
      <WelcomeInput
        icon={<EyeOutlined />}
        value={state.password}
        placeholder={translate('register.password')}
        onChange={value => {
          actions.setField('password', value);
        }}
        type={'password'}
      />
      <Space height={16} />
      <WelcomeInput
        icon={<EyeOutlined />}
        value={state.repeatPassword}
        placeholder={translate('register.repeatPassword')}
        onChange={value => {
          actions.setField('repeatPassword', value);
        }}
        type={'password'}
      />
      <Space height={52} />

      <div style={styles.termsContainer}>
        <Checkbox
          value={state.termsConditions}
          onClick={() => actions.setField('termsConditions', !state.termsConditions)}
        />
        <a href={currentLanguage.termsAndConditionsUrl} target="_blank">
          {translate('register.termsConditions')}
        </a>
      </div>
      <Space height={32} />
      <div style={styles.buttonsContainer}>
        <WelcomeButton
          text={translate('register.goBack')}
          onClick={() => actions.goBack()}
          secondary={true}
        />
        <WelcomeButton
          style={{ flex: 1 }}
          text={translate('registerPaperless.createAccount')}
          onClick={() => actions.validateFieldsAndRegister()}
        />
      </div>

      <Space height={32} />
      {state.loading && <Loading></Loading>}
    </WelcomeLayout>
  );
}
