import { AppContext } from 'App.context';
import CustomButton from 'Components/common/CustomButton/CustomButton';
import Loading from 'Components/common/Loader/Loading';
import Colors from 'constants/Colors';
import React, { useContext, useEffect } from 'react';
import usePmsConfigHook from './PMSConfig.hook';
import styles from './PMSConfig.styles';
import ConfigurationForm from 'Components/common/ConfigurationForm/ConfigurationForm';
import AutomaticConfigurationTest from 'Components/common/ConfigurationForm/AutomaticConfigurationTest/AutomaticConfigurationTest';
import getPMSLogo from 'utils/getPMSLogo';
import AutomaticBookingTest from 'Components/common/ConfigurationForm/AutomaticBookingTest/AutomaticBookingTest';

export default function PMSConfig({ rowData, selectedPms, setPmsCheckin, pmsList }) {
  const { state, actions } = usePmsConfigHook(setPmsCheckin, rowData);
  const {
    appState: { currentLanguage },
    appActions: { translate },
  } = useContext(AppContext);

  useEffect(() => {
    actions.loadPmsConfig(selectedPms, rowData.id);
  }, [selectedPms]);

  const literals =
    selectedPms?.name?.toLowerCase() in currentLanguage.pmsConfig
      ? currentLanguage.pmsConfig[selectedPms?.name?.toLowerCase()]
      : null;

  const pmsSuscribed = pmsList.find(pms => pms.id == rowData.pmsIdSuscribed)?.name;
  const healthCheckConnectionEnabled =
    (pmsSuscribed || rowData.pmsCheckin != 'NoIntegration') &&
    selectedPms.healthCheckConnection === 1;
  const healthCheckBookingEnabled =
    (pmsSuscribed || rowData.pmsCheckin != 'NoIntegration') &&
    selectedPms.healthCheckBooking === 1 &&
    selectedPms.configBookingSearchForm;

  const pmsTranslation = selectedPms?.name
    ? translate(`pmsName.${selectedPms?.name.toLowerCase()}`)
    : '';
  const pmsName = pmsTranslation.includes('.') ? selectedPms?.name : pmsTranslation;
  const showForm = selectedPms.connectionType === 'form' && literals;

  return !state.isLoading ? (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={styles.header}>
          <img style={styles.icon} src={getPMSLogo(selectedPms?.name)}></img>
          <div style={styles.title}>{pmsName}</div>
        </div>

        <div style={{ width: 48 }} />
        {showForm && (
          <div style={{ display: 'flex', gap: 8, flexDirection: 'column', flex: 1 }}>
            {Object.keys(state.orderedConfig)?.map((fieldCategory, index) => {
              return (
                <div key={index}>
                  <div style={styles.category}>{translate(`pmsCategory.${fieldCategory}`)}</div>
                  <ConfigurationForm
                    fields={state.orderedConfig[fieldCategory]}
                    literals={literals}
                    getFieldValue={actions.getFieldValue}
                    onValueChanged={actions.onValueChanged}
                  />
                </div>
              );
            })}
            <div style={{ justifyContent: 'right', display: 'flex' }}>
              <CustomButton
                style={styles.button}
                background={Colors.primaryGreen}
                color={'white'}
                text={translate('save')}
                onClick={() => actions.onSave(selectedPms, rowData.id, rowData.pmsCheckin)}
              />
            </div>
          </div>
        )}
      </div>
      {healthCheckConnectionEnabled && (
        <AutomaticConfigurationTest
          serviceName={selectedPms?.name}
          buttonText={translate('pmsScreen.testPms')}
          buttonAction={actions.doAutomaticTest}
          isTesting={state.isTesting}
          testResult={state.testResult}
          description={translate('pmsScreen.testPmsDesc')}
        />
      )}

      {healthCheckBookingEnabled && (
        <AutomaticBookingTest
          serviceName={selectedPms?.name}
          buttonText={translate('pmsScreen.testBooking')}
          buttonAction={actions.doBookingTest}
          isTesting={state.isTestingBooking}
          testResult={state.testBookingResult}
          description={translate('pmsScreen.testBookingDesc')}
          inputs={Object.values(selectedPms.configBookingSearchForm)[0]}
        />
      )}
    </div>
  ) : (
    <div style={styles.loadingContainer}>
      <Loading />
    </div>
  );
}
