export default {
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 16,
  },
  checkContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  buttonContainer: {
    marginTop: 24,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
};
