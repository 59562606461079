import { AppContext } from 'App.context';
import CustomButton from 'Components/common/CustomButton/CustomButton';
import Loading from 'Components/common/Loader/Loading';
import Colors from 'constants/Colors';
import React, { useContext, useEffect } from 'react';
import styles from './PaymentConfigurationForm.styles';
import ConfigurationForm from 'Components/common/ConfigurationForm/ConfigurationForm';
import usePaymentFormHook from './PaymentConfigurationForm.hook';

export default function PaymentConfigurationForm({
  rowData,
  selectedPayment,
  updateEnabledHotels,
}) {
  const {
    appState: { currentLanguage },
    appActions: { translate },
  } = useContext(AppContext);

  const { state, actions } = usePaymentFormHook(updateEnabledHotels);

  useEffect(() => {
    actions.loadPaymentConfig(selectedPayment, rowData.id);
  }, [selectedPayment]);

  const literals = {
    ...currentLanguage.paymentConfig[selectedPayment?.name?.toLowerCase()],
    ...currentLanguage.paymentConfig.generic,
  };

  return !state.isLoading && state.orderedConfig ? (
    <div style={{ display: 'flex', gap: 8, flexDirection: 'column' }}>
      {Object.keys(state.orderedConfig)?.map((fieldCategory, index) => {
        return (
          <div key={index}>
            <div style={styles.category}>{translate(`paymentCategory.${fieldCategory}`)}</div>

            <ConfigurationForm
              fields={state.orderedConfig[fieldCategory]}
              literals={literals}
              getFieldValue={actions.getFieldValue}
              onValueChanged={actions.onValueChanged}
            />
          </div>
        );
      })}

      <div style={{ justifyContent: 'right', display: 'flex' }}>
        <CustomButton
          style={styles.button}
          background={Colors.primaryGreen}
          // color={'green'}
          text={translate('save')}
          onClick={() => actions.onSave(selectedPayment, rowData.id)}
        />
      </div>
    </div>
  ) : (
    <div style={styles.loadingContainer}>
      <Loading />
    </div>
  );
}
