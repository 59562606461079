module.exports = {
  en: '[hotel_name] | Check-out done!',
  es: '[hotel_name] | Check-out realizado!',
  ca: '[hotel_name] | Check-out completat!',
  de: '[hotel_name] | Check-out erledigt!',
  fr: '[hotel_name] | Check-out effectué!',
  it: '[hotel_name] | Check-out fatto!',
  ja: '[hotel_name] | チェックアウト完了！',
  nl: '[hotel_name] | Check-out gedaan!',
  pt: '[hotel_name] | Check-out feito!',
  pt_BR: '[hotel_name] | Check-out feito!',
  ru: '[hotel_name] | Выезд завершен!',
  zh: '[hotel_name] | 退房完成！',
};
