module.exports = {
  value: `<p>
    Ciao [guest_first_name].&nbsp;Grazie per aver scelto [hotel_name]. Vogliamo davvero riceverti.
    Ecco i dettagli della tua prenotazione e i documenti allegati: </p>
<p>
    Numero di conferma della prenotazione:&nbsp;[reference_number] </p>
<ul>
<li> Data di arrivo:&nbsp;[entrance_date] </li>
<li>
    Data di partenza:&nbsp;[departure_date] </li>
<li> Indirizzo:&nbsp;[address] </li>
</ul>
<p>
    Se hai bisogno di apportare modifiche o hai bisogno di assistenza, chiama [hotel_phone]&nbsp;o inviaci un'e-mail a [CIF_business_email].
    Non vediamo l'ora di darti il ​​benvenuto a [CIF_city]&nbsp;presto!&nbsp;</p>
<p> [hotel_name] </p>
<p> <img src = "[hotel_logo]" larghezza = "200" altezza = "100"> <br> </p>`,
};
