import React, { useContext } from 'react';
import { Card, Image } from 'antd';
import styles from './CheckInGuestDetail.styles';
import CheckInInput from '../CheckInInput/CheckInInput';
import { Row } from '../../../../common/Forms/Row/Row';
import { AppContext } from '../../../../../App.context';
import { buildAuthMediaUrl } from 'utils/networking/oauth/authMedias';

export default function CheckInGuestDetail({ isLoading, data }) {
  const {
    appActions: { translate },
  } = useContext(AppContext);

  const NUM_COLUMNS = 3;

  const HIDDEN_CUSTOM_FIELDS = ['uploadDocument1', 'uploadDocument2'];

  return (
    <Card type={'inner'} loading={isLoading} title={translate('checkInList.detail.guestDetail')}>
      <div style={styles.container}>
        <div style={styles.informationContainer}>
          {groupArrayInColumns(
            data?.guest?.data.filter(f => !HIDDEN_CUSTOM_FIELDS.includes(f.key)),
            NUM_COLUMNS
          )?.map((item, index) => {
            return (
              <Row key={index}>
                {item.map((data, index) => {
                  console.log(item);
                  return <CheckInInput key={index} title={data.label} value={data.value} />;
                })}
              </Row>
            );
          })}
        </div>
        {data?.guest?.documents && (
          <div style={styles.documentsContainer}>
            {data?.guest?.documents?.documentFront && (
              <Image
                style={styles.image}
                src={buildAuthMediaUrl(data?.guest?.documents?.documentFront)}
              />
            )}
            {data?.guest?.documents?.documentBack && (
              <Image
                style={styles.image}
                src={buildAuthMediaUrl(data?.guest?.documents?.documentBack)}
              />
            )}
          </div>
        )}
      </div>
    </Card>
  );
}

const groupArrayInColumns = (arr, size) => {
  return arr?.reduce((acc, _, i) => (i % size ? acc : [...acc, arr.slice(i, i + size)]), []);
};
