import Colors from 'constants/Colors';

export default {
  valueContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    height: 54,
    padding: 8,
    backgroundColor: Colors.primaryGreen,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    color: Colors.white,
    fontWeight: 'bold',
  },
};
