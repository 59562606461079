module.exports = {
  value: `<p>
    Bonjour [guest_first_name].&nbsp;Merci d'avoir choisi [hotel_name]. Nous voulons vraiment vous recevoir.
    Voici le détail de votre réservation et les documents joints: </p>
<p>
    Numéro de confirmation de réservation :&nbsp;[reference_number] </p>
<ul>
<li> Date d'arrivée :&nbsp;[entrance_date] </li>
<li>
    Date de départ :&nbsp;[departure_date] </li>
<li> Adresse :&nbsp;[address] </li>
</ul>
<p>
    Si vous devez apporter des modifications ou demander de l'aide, veuillez appeler [hotel_phone]&nbsp;ou nous envoyer un e-mail à [CIF_business_email].
    Au plaisir de vous accueillir à [CIF_city]&nbsp;bientôt ! ​&nbsp;</p>
<p> [hotel_name] </p>
<p> <img src = "[hotel_logo]" width = "200" height = "100"> <br> </p>`,
};
