import {
  DeleteOutlined,
  EditOutlined,
  UndoOutlined,
  UploadOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import { Button, Upload } from 'antd';
import { AppContext } from 'App.context';
import AppModal from 'Components/common/AppModal/AppModal';
import AppTextInput from 'Components/common/AppTextInput/AppTextInput';
import BaseTable from 'Components/common/BaseTable/BaseTable';
import ContentBox from 'Components/common/ContentBox/ContentBox';
import CustomButton from 'Components/common/CustomButton/CustomButton';
import AppButton from 'Components/common/Forms/AppButton/AppButton';
import Loading from 'Components/common/Loader/Loading';
import DefaultLayout from 'Components/DefaultLayout/DefaultLayout';
import Colors from 'constants/Colors';
import React, { useContext } from 'react';
import useDoorOpeningManteinanceHoook from './DoorOpeningMaintenance.hook';
import styles from './DoorOpeningMaintenance.module.css';
import RequestSuscription from 'Components/common/RequestSuscription/RequestSuscription.jsx';

export default function DoorOpeningMaintenance() {
  const {
    appActions: { translate },
  } = useContext(AppContext);

  const { state, actions } = useDoorOpeningManteinanceHoook();

  const props = {
    accept: '.xlsx',
    customRequest: file => {
      actions.importFromFile(file.file);
    },
    showUploadList: false,
  };

  const columns = [
    {
      title: 'Nombre de la habitación',
      dataIndex: 'name',
      key: 'name',
      searchable: true,
      render: (_, row) => {
        return <div>{row.typeName}</div>;
      },
    },
    {
      title: 'ID PMS',
      dataIndex: 'id-pms',
      key: 'id-pms',
      align: 'center',
      render: (_, row) => {
        return <div>{row.pmsId}</div>;
      },
    },
    {
      title: 'ID Sistema de Apertura',
      key: 'id-opening',
      dataIndex: 'id-opening',
      align: 'center',
      render: (_, row) => {
        return <div>{row.openingId}</div>;
      },
    },
    {
      title: translate('hotels.editCol'),
      key: 'key',
      dataIndex: 'key',
      align: 'center',
      render: (_, row) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <ActionButton row={row} action={actions.onSelectDoorOpening} />
        </div>
      ),
    },
    {
      title: translate('hotels.deleteCol'),
      key: 'key',
      dataIndex: 'key',
      align: 'center',
      render: (_, row) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <ActionButton row={row} action={actions.onDeleteOpeningDoorClicked} isDelete />
        </div>
      ),
    },
  ];

  return (
    <DefaultLayout title={translate('layout.doorOpening')} enableAllHotels={false}>
      {state.suscription ? state.isLoading ? (
        <div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center' }}>
          <Loading></Loading>
        </div>
      ) : (
        <>
          <ContentBox title={translate('doorOpening.add')}>
            <div style={{ padding: 8 }}>
              <Button onClick={actions.downloadTemplate} icon={<DownloadOutlined />}>
                {translate('doorOpening.downloadTemplate')}
              </Button>
              <Upload {...props}>
                <Button icon={<UploadOutlined />}>{translate('doorOpening.import')}</Button>
              </Upload>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  marginTop: 32,
                }}
              >
                <AppTextInput
                  value={state.name}
                  name={translate('doorOpening.roomName')}
                  onChange={text => actions.onNameChanged(text)}
                />
                <AppTextInput
                  value={state.pmsId}
                  name={translate('doorOpening.pmsId')}
                  onChange={text => actions.onPmsIdChanged(text)}
                />
                <AppTextInput
                  value={state.openingId}
                  name={translate('doorOpening.idOpening')}
                  onChange={text => actions.onOpeningIdChanged(text)}
                />
                <div
                  style={{
                    marginTop: 16,
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: 8,
                  }}
                >
                  {state.doorOpeningSelected ? (
                    <Button icon={<UndoOutlined />} onClick={actions.reset} />
                  ) : null}
                  <AppButton
                    text={state.doorOpeningSelected ? translate('save') : translate('add')}
                    action={
                      state.doorOpeningSelected ? actions.editDoorOpening : actions.addDoorOpening
                    }
                  />
                </div>
              </div>
            </div>
          </ContentBox>
          <ContentBox title={translate('doorOpening.list')} noPadding>
            <div style={{ padding: 32 }}>
              <BaseTable columns={columns} data={state.doorsOpeningList} loading={false} />
            </div>
          </ContentBox>
          {state.showDeleteWarning && (
            <div className={styles.alertContainer}>
              <AppModal
                show={state.showDeleteWarning}
                title={translate('hotels.deleteWarningTitle')}
                description={translate('doorOpening.remove')}
                okText={translate('Borrar')}
                okAction={actions.deleteDoorOpening}
                cancelAction={actions.cancelDelete}
              />
            </div>
          )}
        </>) : (
        <RequestSuscription suscription='door_opening' />
      )}
    </DefaultLayout>
  );

  function ActionButton({ action, row, isDelete }) {
    return (
      <div onClick={() => action && action(row)} className={styles.button}>
        {isDelete ? <DeleteOutlined /> : <EditOutlined />}
      </div>
    );
  }
}
