module.exports = {
  en: 'The Online check-out is available for your reservation at [hotel_name]',
  es: 'Check-out online disponible para tu reserva en [hotel_name]',
  ca: 'El check-out online és disponible per a la reserva en [hotel_name]',
  de: 'Der Online Check-out ist für Ihre Reservierung unter [hotel_name] verfügbar.',
  fr: "Le départ en ligne est disponible pour votre réservation à [hotel_name]",
  it: 'Il check-out online è disponibile per la tua prenotazione presso [hotel_name]',
  ja: 'ご予約の [hotel_name] でオンラインチェックアウトが利用可能です',
  nl: 'De online check-out is beschikbaar voor uw reservering bij [hotel_name]',
  pt: 'O Check-out Online está disponível para a sua reserva no [hotel_name]',
  pt_BR: 'O Check-out Online está disponível para a sua reserva no [hotel_name]',
  ru: 'Онлайн-регистрация при выезде доступна для вашего бронирования в [hotel_name]',
  zh: '您可以在 [hotel_name] 预订网上退房'
};
