module.exports = {
  value: `<p>
    Hola [guest_first_name].&nbsp;Gracias por elegir [hotel_name]. Tenemos muchas ganas de recibirte.
    Aquí están los detalles de tu reserva y los documentos adjuntos:</p>
<p>
    Número de confirmación de reserva:&nbsp;[reference_number]</p>
<ul>
    <li>Fecha de llegada:&nbsp;[entrance_date]</li>
    <li>
        Fecha de salida:&nbsp;[departure_date]</li>
    <li> Dirección:&nbsp;[address]</li>
</ul>
<p>
    Si necesitas hacer cambios o requieres asistencia por favor llama [hotel_phone]&nbsp;o mándanos un email a [CIF_business_email].
    ¡Tenemos muchas ganas de recibirte en [CIF_city]&nbsp;pronto!&nbsp;</p>
<p>[hotel_name]</p>
<p><img src="[hotel_logo]" width="auto" height="100"><br></p>`,
};
