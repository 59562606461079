module.exports = {
  value: `<p>
    Hola [guest_first_name].&nbsp;Gràcies per triar [hotel_name]. Tenim moltes ganes de rebre't.
    Aquí tens els detalls de la teva reserva i els documents adjunts:</p>
<p>
    Número de confirmació de reserva:&nbsp;[reference_number]</p>
<ul>
<li>Data d'arribada:&nbsp;[entrance_date]</li>
<li>
    Data de sortida:&nbsp;[departure_date]</li>
<li> Adreça:&nbsp;[address]</li>
</ul>
<p>
    Si necessites fer canvis o requereixes assistència si us plau truca a [hotel_phone]&nbsp;o envia'ns un email a [CIF_business_email].
    Tenim moltes ganes de rebre't a [CIF_city]&nbsp;aviat!&nbsp;</p>
<p>[hotel_name]</p>
<p><img src="[hotel_logo]" width="auto" height="100"><br></p>`,
};
