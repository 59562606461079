module.exports = {
    value: `<p data-pm-slice="1 1 []">Estimado [hotel_name],</p>
<p>
  Me alegra comunicarte que uno de tus huéspedes ha pedido una mejora de
  habitación. A continuación, encontrará la información relevante:
</p>
<ul class="ak-ul">
  <li>
    <p><strong>ID del Upselling:</strong> [upsell_id]</p>
  </li>
  <li>
    <p><strong>Fecha de solicitud del Upselling:</strong> [upsell_request_date]</p>
  </li>
  <li>
    <p><strong>Estado del Upselling:</strong> [upsell_state]</p>
  </li>
  <li>
    <p><strong>Localizador de la reserva:</strong> [booking_identifier]</p>
  </li>
  <li>
    <p>
      <strong>Fecha de inicio y fin de la reserva:</strong> [entrance_date] -
      [departure_date]
    </p>
  </li>
  <li>
    <p><strong>Habitación original:</strong> [original_room_type]</p>
  </li>
  <li>
    <p><strong>Nueva habitación:</strong> [requested_room_type]</p>
  </li>
  <li>
    <p><strong>Precio del upselling:</strong> [upsell_price]</p>
  </li>
  <li>
    <p><strong>Estado del pago:</strong> [payment_status]</p>
  </li>
</ul>
<p>
  Por favor, revise esta solicitud en el panel de Civitfun
  (https://dashboard.civitfun.com/upsell) y confirme si es posible cumplir con
  la subida de categoría de la habitación para vuestro huésped.
</p>
<p>Un saludo,</p>
<p>Equipo Civitfun</p>
`,
  };