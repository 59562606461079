module.exports = {
  value: `<p>
    Hallo [guest_first_name].&nbsp;Vielen Dank, dass Sie sich für [hotel_name] entschieden haben. Wir möchten Sie wirklich empfangen.
    Hier sind die Details Ihrer Reservierung und die beigefügten Dokumente: </p>
<p>
    Buchungsbestätigungsnummer:&nbsp;[reference_number] </p>
<ul>
<li> Ankunftsdatum:&nbsp;[entrance_date] </li>
<li>
    Abreisedatum:&nbsp;[departure_date] </li>
<li> Adresse:&nbsp;[address] </li>
</ul>
<p>
    Wenn Sie Änderungen vornehmen oder Hilfe benötigen, rufen Sie bitte [hotel_phone] an oder senden Sie uns eine E-Mail an [CIF_business_email].
    Wir freuen uns, Sie bald in [CIF_city] begrüßen zu dürfen!
<p> [hotel_name] </p>
<p> <img src = "[hotel_logo]" width = "200" height = "100"> <br> </p>`,
};
