import Colors from 'constants/Colors';

export default {
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 48,
    paddingBottom: 48,
  },
  button: {
    width: '22%',
    backgroundColor: Colors.blueIntense,
    color: Colors.white,
  },
  category: {
    fontWeight: 'bold',
    marginTop: 8,
  },
};
