import React, { useContext } from 'react';
import styles from './TextEditor.styles';
import { Button, Dropdown, Menu, Modal } from 'antd';
import { DownOutlined, EyeFilled } from '@ant-design/icons';
import LegalDocuementsTags from 'Components/Dashboard/LegalDocuments/LegalDocuementsTags';
import useTextEditorHook from './TextEditor.hook';
import JoditEditor from 'jodit-react';
import AppButton from 'Components/common/Forms/AppButton/AppButton';
import { AppContext } from 'App.context';
import { capitalize } from 'utils/TextUtils';
import AppTextInput from 'Components/common/AppTextInput/AppTextInput';
import SaveButton from 'Components/common/SaveButton/SaveButton';

export default function TextEditor({
  type,
  isFirst,
  onSavePressed,
  onPreviewPressed,
  documentDefaultValue,
  labelDefaultValue,
  supportedTypes,
  disabled,
  selectedLanguage,
}) {
  const tags = LegalDocuementsTags;
  const typeConfig = supportedTypes.find(legalType => legalType.id == type);
  const label = supportedTypes.find(legalType => legalType.id == `${type}-label`);

  const typeDefaultValue =
    typeConfig.defaultValue && typeof typeConfig.defaultValue == 'string'
      ? typeConfig.defaultValue
      : typeConfig.defaultValue
      ? typeConfig.defaultValue[selectedLanguage] || typeConfig.defaultValue['en']
      : null;

  const formatedDefaultValue =
    !documentDefaultValue && typeConfig.defaultValue ? typeDefaultValue : documentDefaultValue;

  const { state, actions } = useTextEditorHook(
    formatedDefaultValue,
    labelDefaultValue,
    isFirst,
    onPreviewPressed,
    selectedLanguage
  );
  const {
    appActions: { translate },
  } = useContext(AppContext);

  return (
    <div style={styles.editorContainer}>
      <Modal
        title={translate('legalDocuments.restoreDefault')}
        visible={state.showRecoverDefaultWarning}
        onOk={() => actions.onRecoverDefaultTextPressed(typeDefaultValue)}
        onCancel={() => actions.setShowRecoverDefaultWarning(false)}
      >
        <p>{translate('legalDocuments.restoreDefaultWarning')}</p>
      </Modal>

      <div style={styles.headerContainer}>
        <div style={styles.textContainer}>{capitalize(translate(`legalDocuments.${type}`))}</div>
      </div>

      <div style={styles.header}>
        {label && (
          <AppTextInput
            key={type}
            name={`${translate('legalDocuments.labelHint').replace(
              '#',
              translate(`legalDocuments.${type}`)
            )}`}
            value={state.labelValue}
            onChange={text => actions.setLabelValue(text)}
          />
        )}

        {typeConfig.hasReplacementTags &&
          tags.map((tag, index) => {
            const menuOptions = tag?.tags
              ? tag.tags.map((tag, menuIndex) => {
                  return (
                    <Menu.Item key={menuIndex} onClick={() => actions.insertTag(tag)}>
                      {capitalize(translate(`legalDocuments.tags.${tag}`))}
                    </Menu.Item>
                  );
                })
              : null;

            return tag?.tags ? (
              <Dropdown key={index} overlay={<Menu>{menuOptions}</Menu>} arrow disabled={disabled}>
                <Button size={'small'}>
                  {capitalize(translate(`legalDocuments.tags.${tag.name}`))} <DownOutlined />
                </Button>
              </Dropdown>
            ) : (
              <Button
                key={index}
                size={'small'}
                style={{ margin: 4 }}
                onClick={() => actions.insertTag(tag)}
              >
                {capitalize(translate(`legalDocuments.tags.${tag}`))}
              </Button>
            );
          })}
      </div>

      <div>
        <JoditEditor
          ref={ref => actions.setTextAreaRef(ref)}
          editorRef={ref => actions.setEditorRef(ref)}
          style={{ flex: 1, height: '100%' }}
          value={state.editorValue || formatedDefaultValue}
          config={actions.getEditorConfig(disabled)}
        />
      </div>

      <div style={styles.buttonContainer}>
        {typeConfig.defaultValue && !disabled && (
          <AppButton
            secondary
            action={() => actions.setShowRecoverDefaultWarning(true)}
            text={translate('legalDocuments.restoreDefault')}
          />
        )}
        <AppButton
          secondary
          action={() => onPreviewPressed(actions.getText())}
          text={translate('legalDocuments.previewBtn')}
          icon={<EyeFilled style={{ color: '#708393' }} />}
        />
      </div>
      <div style={styles.saveButtonContainer}>
        {!disabled && (
          <SaveButton
            action={applyToAllHotels =>
              onSavePressed(type, actions.getText(), state.labelValue, applyToAllHotels)
            }
            text={translate('save')}
          />
        )}
      </div>
    </div>
  );
}
