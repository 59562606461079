import { useContext, useEffect } from 'react';
import { useState } from 'react';
import { AppContext } from '../../../App.context';
import { ApiContext } from '../../../utils/networking/Api.context';
import { notification } from 'antd';
import SupportedLegalTypes from './SupportedLegalTypes';

export default function useLegalDocumentsHook() {
  const { API } = useContext(ApiContext);
  const { appState, appActions } = useContext(AppContext);
  const { translate, getSelectedHotel } = appActions;
  const LEGAL_TEXT_TAGS = [
    'legal_cardex',
    'legal_terms_and_conditions',
    'legal_data_privacy_policy',
    'legal_data_commercial_use',
    'legal_loyalty_program',
  ];

  const [state, setState] = useState({
    isLoading: false,
    selectedHotel: null,
    legalDocuments: null,
    replacementTags: null,
    previewText: null,
    languages: null,
    selectedLanguage: null,
  });

  useEffect(() => {
    actions.onHotelSelected(getSelectedHotel());
  }, [appState.selectedHotel]);

  const actions = {
    loadLegalDocuments: hotelId => {
      updateState({ isLoading: true });

      API.legalTextsRequest(hotelId)
        .then(data => {
          const legalDocuments = data.hotelTexts.reduce((result, document) => {
            result[document.type] = result[document.type] || [];
            result[document.type].push(document);
            return result;
          }, Object.create(null));

          const filledDocuments = actions.addMissingDocuments(legalDocuments, data?.languages);
          const orderedDocuments = actions.getOrderedTypes(filledDocuments);

          updateState({
            legalDocuments: orderedDocuments,
            replacementTags: data.tags,
            languages: data.languages,
            isLoading: false,
            selectedLanguage:
              !state.selectedLanguage ||
              !data.languages.map(l => l.code).includes(state.selectedLanguage)
                ? data.languages[0].code
                : state.selectedLanguage,
          });
        })
        .catch(error => {
          console.warn(error);
          updateState({ isLoading: false });
        });
    },

    addMissingDocuments: (legalDocuments, languages) => {
      SupportedLegalTypes.forEach(type => {
        const loadedDocument = legalDocuments[type.id];
        if (!loadedDocument) {
          legalDocuments[type.id] = [];
        }
      });

      languages.forEach(lang => {
        SupportedLegalTypes.forEach(type => {
          const alreadyExists = legalDocuments[type.id].find(
            document => document.lang == lang.code
          );

          if (!alreadyExists) {
            legalDocuments[type.id].push({
              type: type.id,
              lang: lang.code,
              value: '',
            });
          }
        });
      });

      return legalDocuments;
    },

    getOrderedTypes: legalDocuments => {
      const orderedDocuments = [];
      orderedDocuments.push(legalDocuments['custom_cardex_pdf']);
      orderedDocuments.push(legalDocuments['cardex']);
      orderedDocuments.push(legalDocuments['terms']);
      orderedDocuments.push(legalDocuments['data-privacy-policy']);
      orderedDocuments.push(legalDocuments['loyalty-program']);
      orderedDocuments.push(legalDocuments['data-commercial-use']);
      orderedDocuments.push(legalDocuments['data-privacy-policy-label']);
      orderedDocuments.push(legalDocuments['data-commercial-use-label']);
      orderedDocuments.push(legalDocuments['loyalty-program-label']);

      return orderedDocuments;
    },

    updateLegalText: (type, value, label, applyToAllHotels) => {
      updateState({ isLoading: true });
      API.updateLegalTextRequest({
        hotelId: !applyToAllHotels ? state.selectedHotel?.key : null,
        type,
        value,
        lang: state.selectedLanguage,
        label: label,
      })
        .then(_ => {
          notification.success({
            message: translate('legalDocuments.success'),
          });
          if (state.selectedHotel) {
            const legalDocumentsCopy = Object.assign([], state.legalDocuments);

            actions.updateDocumentsLocally(legalDocumentsCopy, type, value, label);

            updateState({ isLoading: false, legalDocuments: legalDocumentsCopy });
          } else {
            updateState({ isLoading: false });
          }
        })
        .catch(err => {
          notification.error({
            message: translate('legalDocuments.error'),
          });
          updateState({ isLoading: false });
        });
    },

    updateDocumentsLocally: (legalDocumentsCopy, type, value, label) => {
      const modifiedSet = legalDocumentsCopy.find(set => set[0].type == type);
      actions.updateLocalSet({ documentsSet: modifiedSet, type, value });

      const modifiedLabelSet = legalDocumentsCopy.find(set => set[0].type == `${type}-label`);
      if (modifiedLabelSet) {
        actions.updateLocalSet({
          documentsSet: modifiedLabelSet,
          type: `${type}-label`,
          value: label,
        });
      }
    },

    updateLocalSet: ({ documentsSet, type, value }) => {
      const modifiedLabelDocument = documentsSet?.find(
        document => document.lang == state.selectedLanguage
      );

      if (modifiedLabelDocument) {
        modifiedLabelDocument.value = value;
      } else {
        documentsSet.push({
          type,
          lang: state.selectedLanguage,
          value: value,
        });
      }
    },

    onHotelSelected: hotel => {
      updateState({ selectedHotel: hotel });
      if (hotel && hotel?.key != 'null') {
        actions.loadLegalDocuments(hotel?.key);
      }
    },

    onPreviewPressed: htmlText => {
      if (state.replacementTags) {
        Object.keys(state.replacementTags).forEach(key => {
          htmlText = htmlText.replaceAll(`[${key}]`, state.replacementTags[key]);
        });

        LEGAL_TEXT_TAGS.forEach(legalTag => {
          let type = legalTag.replace('legal_', '').replaceAll('_', '-');
          if (type === 'terms-and-conditions') type = 'terms';

          const documentSet = state.legalDocuments.find(set => set[0].type == type);
          const document = documentSet?.find(doc => doc.lang == state.selectedLanguage);
          if (document?.value) {
            if (type === 'terms') type = 'terms-and-conditions';
            htmlText = htmlText.replaceAll(`[legal_${type.replaceAll('-', '_')}]`, document?.value);
          }
        });
      }

      updateState({ previewText: htmlText });
    },

    onSavePressed: (type, value, label, applyToAllHotels) => {
      actions.updateLegalText(type, value, label, applyToAllHotels);
    },

    onLanguageSelected: lang => {
      updateState({ selectedLanguage: lang });
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}
