import { AppContext } from 'App.context';
import { useEffect } from 'react';
import { useContext, useState } from 'react';
import { ApiContext } from 'utils/networking/Api.context';
import { capitalize, camelToUnderscore } from 'utils/TextUtils';


export default function useMarketingHook() {
  const { appState, appActions } = useContext(AppContext);
  const { API } = useContext(ApiContext);
  const chainSlug = appState.chain?.slug;
  const { translate, getSelectedHotel } = appActions;

  const [state, setState] = useState({
    hotel: null,
    data: null,
    configOptions: null,
  });

  useEffect(() => {
    actions.setHotel(getSelectedHotel());
  }, [appState.selectedHotel]);

  const actions = {
    loadTableLinks: () => {
      const data = appState?.hotelList?.map((hotel, index) => {
        return {
          key: index + 1,
          name: capitalize(hotel?.name),
          url: `https://checkin.civitfun.com/hotel/${hotel?.slug}`,
        };
      });

      if (data && data.length) {
        data.unshift({
          key: 0,
          name: translate('marketing.chainCheckin'),
          url: `https://checkin.civitfun.com/chain/${chainSlug}`,
        });
      }

      updateState({ data });
    },

    setHotel: hotel => {
      updateState({ hotel });
      actions.loadTableLinks();
      actions.loadCheckinConfig(hotel?.key);
    },

    getSelectedHotelSlug: () => {
      const completeHotel = appState?.hotelList?.find(
        savedHotel => savedHotel.id == state.hotel?.key
      );
      return completeHotel?.slug;
    },

    getChainSlug: () => {
      return chainSlug;
    },

    loadCheckinConfig: hotelId => {
      if (!hotelId) return;
      updateState({ isLoading: true });
      API.bookingSearchConfigRequest(hotelId)
        .then(res => {
          updateState({
            isLoading: false,
            configOptions: res,
          });
        })
        .catch(_ => {});
    },

    getBookingIdentifierLink: () => {
      return `https://checkin.civitfun.com/hotel/${actions.getSelectedHotelSlug()}/startCheckin/[BOOKING_IDENTIFIER]`;
    },

    getHotelDeeplink: () => {
      let link = `https://checkin.civitfun.com/hotel/${actions.getSelectedHotelSlug()}/bookingSearchForm?`;

      if (state.configOptions && state.configOptions.length) {
        state.configOptions.forEach((option, index) => {
          if (option.required == 1 && option.state == 'active') {
            link = `${link}${option.name}=[${camelToUnderscore(option.name)}]`;

            if (index != state.configOptions.length - 1) {
              link = `${link}&`;
            }
          }
        });
      }  

      link = `${link}&autoSearch`;

      return link;
    },

    getHotelDeeplinkBooking: () => {
      let link = `https://checkin.civitfun.com/hotel/${actions.getSelectedHotelSlug()}/bookingSearchForm?channel=bookingcommessages`;

      return link;
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}
