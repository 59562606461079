module.exports = {
  value: `<!DOCTYPE html>
<html style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;">
<head>
<meta name="viewport" content="width=device-width" />
<meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
<!--[if !mso]><!-->

<!--<![endif]-->

<table style="margin: auto;  width: 100%;"><tr>
  <td style="text-align: center; vertical-align: middle;">
    <img src="[hotel_logo]" style="margin: auto;  object-fit: contain; height: 100px;" width="auto" height="100"></td>
</tr></table>
<style type="text/css">
    @import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700);
</style>
<!--<![endif]-->
<style type="text/css">
    @media only screen and (min-width: 480px) {
      .mj-column-per-100 {
        width: 100% !important;
        max-width: 100%;
      }
    }
</style>
<style media="screen and (min-width:480px)">
    .moz-text-html .mj-column-per-100 {
      width: 100% !important;
      max-width: 100%;
    }
</style>
<style type="text/css">
    [owa] .mj-column-per-100 {
      width: 100% !important;
      max-width: 100%;
    }
</style>
<style type="text/css">
    @media only screen and (max-width: 480px) {
      table.mj-full-width-mobile {
        width: 100% !important;
      }
      td.mj-full-width-mobile {
        width: auto !important;
      }
    }
</style>
<style>
    a:link { text-decoration: none !important; }
    a:visited { text-decoration: none !important; }
    a:hover { text-decoration: none !important; }
    a:active { text-decoration: none !important; }
</style>


<div style="background-color: #f4f4f4">
    <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div style="margin: 0px auto; max-width: 600px">
        <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width: 100%">
            <tbody>
                <tr>
                    <td style="
                  direction: ltr;
                  font-size: 0px;
                  padding: 20px 0;
                  padding-bottom: 0px;
                  padding-top: 0px;
                  text-align: center;
                ">
                        <!--[if mso | IE]></td></tr></table><![endif]-->
                        <br>
                    </td>

                </tr>

            </tbody>

        </table>
        <br>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div style="
          background: #ffffff;
          background-color: #ffffff;
          margin: 0px auto;
          max-width: 600px;
        ">
        <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background: #ffffff; background-color: #ffffff; width: 100%">
            <tbody>
                <tr>
                    <td style="
                  direction: ltr;
                  font-size: 0px;
                  padding: 20px 0px 20px 0px;
                  text-align: center;
                ">
                        <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
                        <div class="mj-column-per-100 mj-outlook-group-fix" style="
                    font-size: 0px;
                    text-align: left;
                    direction: ltr;
                    display: inline-block;
                    vertical-align: top;
                    width: 100%;
                  ">
                            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align: top" width="100%">
                                <tbody>
                                    <tr>
                                        <td align="left" style="
                            font-size: 0px;
                            padding: 0px 25px 0px 25px;
                            padding-top: 0px;
                            padding-bottom: 0px;
                            word-break: break-word;
                          ">
                                            <div style="
                              font-family: Ubuntu, Helvetica, Arial, sans-serif;
                              font-size: 13px;
                              line-height: 1;
                              text-align: left;
                              color: #000000;
                            ">
                                                <h1 style="
                                text-align: left;
                                margin-top: 10px;
                                margin-bottom: 10px;
                                font-weight: normal;
                              ">
                                                    <span style="
                                  line-height: 22px;
                                  font-size: 20px;
                                  letter-spacing: normal;
                                  text-align: left;
                                  color: #55575d;
                                  font-family: Arial;
                                "></span>
                                                </h1>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="left" style="
                            font-size: 0px;
                            padding: 0px 25px 0px 25px;
                            padding-top: 0px;
                            padding-bottom: 0px;
                            word-break: break-word;
                          ">
                                                                  
                                            <div style="
                              font-family: Ubuntu, Helvetica, Arial, sans-serif;
                              font-size: 13px;
                              line-height: 1;
                              text-align: left;
                              color: #000000;
                            ">
                                                <p style="
                                text-align: left;
                                margin: 10px 0;
                                margin-top: 10px;
                              ">
                                    </p>
                                <p style="text-align: left; margin: 10px 0">
                                                    <span style="
                                  line-height: 22px;
                                  font-size: 14px;
                                  font-weight: bold;
                                  letter-spacing: normal;
                                  text-align: left;
                                  color: #55575d;
                                  font-family: Arial;
                                ">The [hotel_name] awaits you next [entrance_date].</span>
                                                </p>
                                                <p style="
                                text-align: left;
                                margin: 10px 0;
                                margin-bottom: 10px;
                              ">
                                                    <span style="
                                  line-height: 22px;
                                  font-size: 13px;
                                  letter-spacing: normal;
                                  text-align: left;
                                  color: #55575d;
                                  font-family: Arial;
                                ">You can check-in your reservation online before your arrival at [hotel_name] completely free of charge. 
                                Save time at reception avoiding unnecessary queue with online Check-In.</span>
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="center" vertical-align="middle" style="
                            font-size: 0px;
                            padding: 10px 25px;
                            word-break: break-word;
                          ">
                                            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse: separate; line-height: 100%">
                                                <tbody>
                                                    <tr>
                                                        <td align="center" bgcolor="#414141" role="presentation" style="
                                    border: none;
                                    border-radius: 3px;
                                    cursor: auto;
                                    mso-padding-alt: 10px 25px;
                                    background: #414141;
                                  " valign="middle">
                                    <p style="
                                      display: inline-block;
                                      background: #[secondary_color];
                                      color: #ffffff;
                                      font-family: Ubuntu, Helvetica, Arial,
                                        sans-serif;
                                      font-size: 13px;
                                      font-weight: normal;
                                      line-height: 120%;
                                      margin: 0;
                                      text-decoration: none;
                                      text-transform: none;
                                      padding: 10px 25px;
                                      mso-padding-alt: 0px;
                                      border-radius: 3px;
                                    ">
                                  <a href=[checkin_url] style="
                                        font-size: 14px;
                                        text-align: center;
                                        color: #ffffff;
                                        font-family: Arial;
                                        text-decoration: none !important;
                                      ">CHECK-IN ONLINE</a>
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!--[if mso | IE]></td></tr></table><![endif]-->
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div style="
          background: #ffffff;
          background-color: #ffffff;
          margin: 0px auto;
          max-width: 600px;
        ">

        <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background: #ffffff; background-color: #ffffff; width: 100%">
            <tbody>
                <tr>
                    <td style="
                  direction: ltr;
                  font-size: 0px;
                  padding: 20px 0;
                  padding-bottom: 0px;
                  padding-top: 0px;
                  text-align: center;
                ">
                        <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
                        <div class="mj-column-per-100 mj-outlook-group-fix" style="
                    font-size: 0px;
                    text-align: left;
                    direction: ltr;
                    display: inline-block;
                    vertical-align: top;
                    width: 100%;
                  ">
                            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align: top" width="100%">
                                <tbody>
                                    <tr>
                                        <td align="center" style="
                            font-size: 0px;
                            padding: 10px 25px;
                            padding-top: 0px;
                            padding-bottom: 0px;
                            word-break: break-word;
                          ">
                                            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="
                              border-collapse: collapse;
                              border-spacing: 0px;
                            ">
                                                <tbody>
                                                    <tr>
                                                        <td style="width: 550px">
                                                            <img alt="" height="auto" src="https://x0n6o.mjt.lu/tplimg/x0n6o/b/l39ql/gm5nz.png" style="
                                      border: none;
                                      border-radius: px;
                                      display: block;
                                      outline: none;
                                      text-decoration: none;
                                      height: auto;
                                      width: 100%;
                                      font-size: 13px;
                                    " width="550">
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!--[if mso | IE]></td></tr></table><![endif]-->
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    <div style="
          background: #ffffff;
          background-color: #ffffff;
          margin: 0px auto;
          max-width: 600px;
        ">
        
        <table style="
            background: #ffffff;
            background-color: #ffffff;
            margin: 0px auto;
            width: 100%;
            text-align: center;
            font-size: 11px;
            padding: 4px;
        ">
            <tr>
                <td style="padding: 4px;;">
                  You are receiving this email because the hotel where you are staying uses the Civitfun Hospitality digital services.
                </td>
            </tr>
        </table>

        <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background: #ffffff; background-color: #ffffff; width: 100%">
            <tbody>
                <tr>
                    <td style="
                  direction: ltr;
                  font-size: 0px;
                  padding: 20px 0;
                  text-align: center;
                ">
                        <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
                        <div class="mj-column-per-100 mj-outlook-group-fix" style="
                    font-size: 0px;
                    text-align: left;
                    direction: ltr;
                    display: inline-block;
                    vertical-align: top;
                    width: 100%;
                  ">
                            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align: top" width="100%">
                                <tbody>
                                    <tr>
                                        <td align="left" style="
                            font-size: 0px;
                            padding: 10px 25px;
                            padding-top: 0px;
                            padding-bottom: 0px;
                            word-break: break-word;
                          ">
                                            <div style="
                              font-size: 13px;
                              line-height: 1;
                              text-align: left;
                              color: #000000;
                            ">
                                                <p style="
                                text-align: left;
                                margin: 10px 0;
                                margin-top: 10px;
                              ">
                                                    <span style="
                                  font-size: 11px;
                                  letter-spacing: normal;
                                  text-align: left;
                                  color: #000000;
                                ">[hotel_name]</span>
                                                </p>
                                                <p style="
                                text-align: left;
                                margin: 10px 0;
                                margin-bottom: 10px;
                              ">
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!--[if mso | IE]></td></tr></table><![endif]-->
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <!--[if mso | IE]></td></tr></table><![endif]-->
</div>

<p>
    <grammarly-desktop-integration data-grammarly-shadow-root="true"></grammarly-desktop-integration>

    <br>
</p>
<style type="text/css">
    #outlook a {
      padding: 0;
    }
    body {
      margin: 0;
      padding: 0;
      -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
    }
    table,
    td {
      border-collapse: collapse;
      mso-table-lspace: 0pt;
      mso-table-rspace: 0pt;
    }
    img {
      border: 0;
      height: auto;
      line-height: 100%;
      outline: none;
      text-decoration: none;
      -ms-interpolation-mode: bicubic;
    }
    p {
      display: block;
      margin: 13px 0;
    }
</style>
</html>`,
};
