export default `</p><p align="center">
<img src="[chain_logo]" width="auto" height="100">
</p>

<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;text-align:center;"><strong><span style="font-size:21px;font-family:&quot;Calibri&quot;,sans-serif;">TARJETA DE REGISTRO / REGISTRATION CARD&nbsp;</span></strong></p>
<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;">&nbsp;</span></p>
<p style="margin-right:0cm;margin-left:-.25pt;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;margin-top:0cm;margin-bottom:.15pt;text-indent:-.5pt;"><strong><span style="font-size:19px;font-family:&quot;Calibri&quot;,sans-serif;">DATOS DEL ESTABLECIMIENTO / ESTABLISHMENT DETAILS&nbsp;</span></strong></p>
<table style="float: left;border: none;width:399.15pt;border-collapse:collapse;margin-left:-2.25pt;margin-right:-2.25pt;">
<tbody>
    <tr>
        <td style="width: 148.6pt;padding: 0cm;height: 14.65pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Parte nº/Cardex nº:&nbsp;</span></p>
        </td>
        <td style="width: 250.55pt;padding: 0cm;height: 14.65pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[police_report_number]&nbsp;</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 148.6pt;padding: 0cm;height: 14.65pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Nombre del Hotel/Hotel Name:&nbsp;</span></p>
        </td>
        <td style="width: 250.55pt;padding: 0cm;height: 14.65pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[hotel_name]&nbsp;</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 148.6pt;padding: 0cm;height: 14.65pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">CIF/VAT/NIF:&nbsp;</span></p>
        </td>
        <td style="width: 250.55pt;padding: 0cm;height: 14.65pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[CIF]&nbsp;</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 148.6pt;padding: 0cm;height: 14.65pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Dirección/Adress:&nbsp;</span></p>
        </td>
        <td style="width: 250.55pt;padding: 0cm;height: 14.65pt;vertical-align: top;">
            <p style="margin-right:2.7pt;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[CIF_address], C.P.. [CIF_postal_code]&nbsp;</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 148.6pt;padding: 0cm;height: 14.65pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Municipio/City:&nbsp;</span></p>
        </td>
        <td style="width: 250.55pt;padding: 0cm;height: 14.65pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[CIF_city]&nbsp;</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 148.6pt;padding: 0cm;height: 14.65pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Provincia/Province:&nbsp;</span></p>
        </td>
        <td style="width: 250.55pt;padding: 0cm;height: 14.65pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">[CIF_province]&nbsp;</span></p>
        </td>
    </tr>
</tbody>
</table>
<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;">&nbsp;</span></p>
<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;">&nbsp;</span></p>
<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;">&nbsp;</span></p>
<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;">&nbsp;</span></p>
<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;">&nbsp;</span></p>
<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-family:&quot;Calibri&quot;,sans-serif;">&nbsp;</span></p>
<p style="margin-right:0cm;margin-left:-.25pt;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;margin-top:0cm;margin-bottom:.15pt;text-indent:-.5pt;"><strong><span style="font-family:&quot;Calibri&quot;,sans-serif;">&nbsp;</span></strong></p>
<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;margin-bottom:.15pt;"><strong><span style="font-family:&quot;Calibri&quot;,sans-serif;">&nbsp;</span></strong></p>
<p style="margin-right:0cm;margin-left:-.25pt;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;margin-top:0cm;margin-bottom:.15pt;text-indent:-.5pt;"><strong><span style="font-size:19px;font-family:&quot;Calibri&quot;,sans-serif;">DATOS DEL VIAJERO / HUESPED DETAILS</span></strong></p>
<p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;">&nbsp;</span></p>
<table style="width:439.2pt;border-collapse:collapse;border:none;">
<tbody>
    <tr>
        <td style="width: 86.1pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><strong><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">Nombre/Name:</span></strong></p>
        </td>
        <td style="width: 108.4pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">[guest_first_name]</span></p>
        </td>
        <td style="width: 110.05pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><strong><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">País del doc. /Doc. Country:</span></strong></p>
        </td>
        <td style="width: 134.65pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">[guest_nationality]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 86.1pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><strong><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">Apellido/Last name:</span></strong></p>
        </td>
        <td style="width: 108.4pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">[guest_surname]</span></p>
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">[guest_second_surname]</span></p>
        </td>
        <td style="width: 110.05pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><strong><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">Tipo Doc./Doc. Type:</span></strong></p>
        </td>
        <td style="width: 134.65pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">[guest_document_type]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 86.1pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><strong><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">Dirección/Address:</span></strong></p>
        </td>
        <td style="width: 108.4pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">[guest_address]</span></p>
        </td>
        <td style="width: 110.05pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><strong><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">Nº Doc./Nº of Doc.:</span></strong></p>
        </td>
        <td style="width: 134.65pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">[guest_document_number]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 86.1pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><strong><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">C.P./Zip Code:</span></strong></p>
        </td>
        <td style="width: 108.4pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">[guest_postal_code]</span></p>
        </td>
        <td style="width: 110.05pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><strong><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">Fecha Expedición. /Iss. Date:</span></strong></p>
        </td>
        <td style="width: 134.65pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">[guest_expedition_date]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 86.1pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><strong><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">Ciudad/City</span></strong></p>
        </td>
        <td style="width: 108.4pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">[guest_city]</span></p>
        </td>
        <td style="width: 110.05pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><strong><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">Fecha caducidad /Exp. Date:</span></strong></p>
        </td>
        <td style="width: 134.65pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">[guest_expiration_date]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 86.1pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><strong><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">Provincia/Province</span></strong></p>
        </td>
        <td style="width: 108.4pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">[guest_province]</span></p>
        </td>
        <td style="width: 110.05pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;">&nbsp;</span></p>
        </td>
        <td style="width: 134.65pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;">&nbsp;</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 86.1pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><strong><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">País/Country</span></strong></p>
        </td>
        <td style="width: 108.4pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">[guest_country]</span></p>
        </td>
        <td style="width: 110.05pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><strong><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">Localizador/booking number</span></strong></p>
        </td>
        <td style="width: 134.65pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">[reference_number]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 86.1pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><strong><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">e-mail:</span></strong></p>
        </td>
        <td style="width: 108.4pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">[guest_email]</span></p>
        </td>
        <td style="width: 110.05pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><strong><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">Llegada/Arrival</span></strong></p>
        </td>
        <td style="width: 134.65pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">[entrance_date]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 86.1pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><strong><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">Móvil/Mobile:</span></strong></p>
        </td>
        <td style="width: 108.4pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">[guest_phone]</span></p>
        </td>
        <td style="width: 110.05pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><strong><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">Salida/departure/</span></strong></p>
        </td>
        <td style="width: 134.65pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">[departure_date]</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 86.1pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><strong><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">Sexo/Gender:</span></strong></p>
        </td>
        <td style="width: 108.4pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">[guest_gender]</span></p>
        </td>
        <td style="width: 110.05pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;">&nbsp;</span></p>
        </td>
        <td style="width: 134.65pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;">&nbsp;</span></p>
        </td>
    </tr>
    <tr>
        <td style="width: 86.1pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><strong><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">F. Nac./Birth Date:</span></strong></p>
        </td>
        <td style="width: 108.4pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;color:black;">[guest_birth_date]</span></p>
        </td>
        <td style="width: 110.05pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;">&nbsp;</span></p>
        </td>
        <td style="width: 134.65pt;padding: 0cm 5.4pt;vertical-align: top;">
            <p style="margin-right:0cm;margin-left:0cm;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;"><span style="font-size:11px;font-family:&quot;Calibri&quot;,sans-serif;">&nbsp;</span></p>
        </td>
    </tr>
    
</tbody>
</table>

<p><br>
<br>

[legal_cardex]

<br>
<br>
<br>

</p><p style="margin-right:0cm;margin-left:-.25pt;font-size:16px;font-family:&quot;Times New Roman&quot;,serif;margin:0cm;margin-top:0cm;margin-bottom:.15pt;text-indent:-.5pt;"><strong><span style="font-size:13px;font-family:&quot;Calibri&quot;,sans-serif;">Firma / Signature</span></strong></p>


<p>
<img src="[guest_signature]" width="auto" height="200">
</p><p>`;
