import FIELDS from '../PoliceReportFields';
import moment from 'moment';
import validateDocumentNumber from './validateDocumentNumber';
import PoliceReportConstants from '../police.constants';
import { isEmpty, validateEmail } from 'utils/TextUtils';
import isValidPostalCode from './validatePostalCode';
import mandatoryFields from '../PoliceReportMandatoryFields';

const { ADULT_AGE } = PoliceReportConstants;

function isMandatoryFieldEmpty(report, field) {
  const isEmptyField = field => isEmpty(report[field]);
  const birthDate = moment(report[FIELDS.BIRTHDATE_KEY], ['DD-MM-YYYY', 'YYYY-MM-DD']);
  const today = moment();
  const isAdult = birthDate.isValid() && today.diff(birthDate, 'years') > ADULT_AGE;

  function isMandatoryField() {
    const requiredFields = [
      ...mandatoryFields,
      report[FIELDS.COUNTRY_KEY] === 'ESP' ? FIELDS.MUNICIPALITY_KEY : FIELDS.MUNICIPALITYNAME_KEY,
      isAdult && report[FIELDS.CHILDREN_KEY] > 0 ? FIELDS.FAMILYRELATIONSHIP_KEY : null,
    ].filter(item => item != null);

    return requiredFields.includes(field) && isEmptyField(field);
  }

  function isSurnameOrDocumentSupportField() {
    const mandatorySurnameDoc = ['NIF', 'NIE', 'dni', 'residencePermit'];
    return (
      (field === FIELDS.SECONDSURNAME_KEY || field === FIELDS.DOCUMENTSUPPORT_KEY) &&
      mandatorySurnameDoc.includes(report[FIELDS.DOCUMENTTYPE_KEY]) &&
      isEmptyField(field)
    );
  }

  function isDocumentTypeOrNumberField() {
    return (
      (field === FIELDS.DOCUMENTTYPE_KEY || field === FIELDS.DOCUMENTNUMBER_KEY) &&
      isAdult &&
      isEmptyField(field)
    );
  }

  function isFamilyRelationshipField() {
    return field === FIELDS.FAMILYRELATIONSHIP_KEY && !isAdult && isEmptyField(field);
  }

  return (
    isMandatoryField() ||
    isSurnameOrDocumentSupportField() ||
    isDocumentTypeOrNumberField() ||
    isFamilyRelationshipField()
  );
}

function getFieldValidations(report, translate) {
  const fieldValidations = {
    [FIELDS.DOCUMENTNUMBER_KEY]: {
      validate: (guest) =>
        validateDocumentNumber(guest[FIELDS.DOCUMENTTYPE_KEY], guest[FIELDS.DOCUMENTNUMBER_KEY], guest[FIELDS.COUNTRY_KEY]),
    },
    [FIELDS.ZIPCODE_KEY]: {
      validate: (guest) => isValidPostalCode(guest[FIELDS.ZIPCODE_KEY], guest[FIELDS.COUNTRY_KEY]),
    },
    [FIELDS.CONTACTVALUE_KEY]: {
      validate: (guest) => {
        if (FIELDS.CONTACTTYPE_KEY in guest && guest[FIELDS.CONTACTTYPE_KEY] === 'email') {
          return validateEmail(guest[FIELDS.CONTACTVALUE_KEY]);
        } else {
          return true;
        }
      },
    },
    [FIELDS.BIRTHDATE_KEY]: {
      validate: (guest) => {
        const date = moment(guest[FIELDS.BIRTHDATE_KEY], ['DD-MM-YYYY', 'YYYY-MM-DD']);
        return date.isValid() && date <= moment() && date.year() >= 1900;
      },
    },
  };

  if (translate) {
    fieldValidations[FIELDS.DOCUMENTNUMBER_KEY].error = translate(
      `policeReport.error.documentNumber`
    );
    fieldValidations[FIELDS.ZIPCODE_KEY].error = translate(`policeReport.error.zipCode`);
    fieldValidations[FIELDS.CONTACTVALUE_KEY].error = translate(`policeReport.error.emailError`);
    fieldValidations[FIELDS.BIRTHDATE_KEY].error = translate(`policeReport.error.birthDate`);
  }

  return fieldValidations;
}

export default { isMandatoryFieldEmpty, getFieldValidations };
