import Colors from 'constants/Colors';

export default {
  category: {
    fontWeight: 'bold',
    marginTop: 8,
  },
  fieldsGrid: {
    display: 'grid',
    gridTemplateColumns: '24% 24% 24% 24%',
    padding: '0px',
    margin: '0px',
    boxSizing: 'border-box',
    gap: 8,
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 48,
    paddingBottom: 48,
  },
  button: {
    width: '22%',
    backgroundColor: Colors.blueIntense,
    color: Colors.white,
  },
  icon: {
    width: 82,
    height: 82,
  },
  title: {
    fontSize: 22,
    fontWeight: 'bold',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
    justifyContent: 'left',
    marginBottom: 16,
  },
};
