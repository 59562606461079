module.exports = {
  en: '[hotel_name] | Check-in done!',
  es: '[hotel_name] | Check-in realizado!',
  ca: '[hotel_name] | Check-in completat!',
  de: '[hotel_name] | Einchecken erledigt!',
  fr: '[hotel_name] | Enregistrement effectué!',
  it: '[hotel_name] | Check-in fatto!',
  ja: '[hotel_name] | チェックイン完了！',
  nl: '[hotel_name] | Inchecken gedaan!',
  pt: '[hotel_name] | Check-in feito!',
  pt_BR: '[hotel_name] | Check-in feito!',
  ru: '[hotel_name] | Регистрация завершена!',
  zh: '[hotel_name] | 办理登机手续！',
};
