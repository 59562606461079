import { notification } from 'antd';
import { AppContext } from 'App.context';
import { useContext, useEffect, useState } from 'react';
import { ApiContext } from 'utils/networking/Api.context';
import { validateEmail } from 'utils/TextUtils';

export default function useGuestlinkConfigHook() {
  const {
    appState,
    appActions: { getSelectedHotel, translate },
  } = useContext(AppContext);
  const { API } = useContext(ApiContext);

  const SSL_TLS_LABEL = 'SSL/TLS';
  const SSL_TLS_VALUE = 'ssl';
  const STARTTLS_LABEL = 'STARTTLS';
  const STARTTLS_VALUE = 'start-tls';

  const [state, setState] = useState({
    isLoading: false,
    showSendTestEmailModal: false,
    testEmailAddress: null,
    isSendingTestEmail: false,
    host: null,
    port: null,
    user: null,
    password: null,
    security_layer: SSL_TLS_VALUE,
    smtpAuth: true,
    default_sender: null,
    suscription: null,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    
    actions.getGuestlinkSuscriptionStatus();
    actions.loadGuestlinkConfig();
  }, [appState.selectedHotel]);

  const actions = {
    getGuestlinkSuscriptionStatus: () => {
      updateState({ isLoading: true });
      API.getGuestlinkSuscriptionStatus({ hotelId: getSelectedHotel()?.key }).then(res => {
        updateState({ suscription: res });
      });
    },

    loadGuestlinkConfig: () => {

      API.getGuestlinkSmtpConfig({ hotelId: getSelectedHotel()?.key })
        .then(res => {
          updateState({
            isLoading: false,
            showSendTestEmailModal: false,
            testEmailAddress: null,
            isSendingTestEmail: false,
            host: res?.host,
            port: res?.port,
            security_layer: res?.security_layer || SSL_TLS_VALUE,
            smtpAuth: res?.smtpAuth || true,
            user: res?.user,
            password: res?.password,
            default_sender: res?.default_sender,
          });
        })
        .catch(err => {
          console.warn(err);
          updateState({ isLoading: false });
        })
    },

    getSecurityLayerOptions: () => {
      return [
        { value: SSL_TLS_VALUE, label: SSL_TLS_LABEL },
        { value: STARTTLS_VALUE, label: STARTTLS_LABEL },
      ];
    },

    getSmtpAuthOptions: () => {
      return [
        { value: true, label: translate('guestlink.enabled') },
        { value: false, label: translate('guestlink.disabled') },
      ];
    },

    onSaveSmtpConfig: (applyToAllHotels) => {
      updateState({ isLoading: true });

      const { isLoading, showSendTestEmailModal, testEmailAddress, isSendingTestEmail, ...smtpConfig } = state;

      const {
        host,
        port,
        security_layer,
        user,
        password,
        default_sender
      } = smtpConfig;

      if (!host || !port || !security_layer || !user || !password || !default_sender) {
        notification.error({
          message: translate('guestlink.requiredFieldsError'),
        });
        updateState({ isLoading: false });
      } else {
        API.updateGuestlinkSmtpConfig({ hotelId: getSelectedHotel()?.key, smtpConfig, applyToAllHotels })
          .then(_ => {
            updateState({ isLoading: false });
            notification.success({
              message: translate('guestlink.smtpSuccess'),
            })
          })
          .catch(err => {
            console.warn(err);
            updateState({ isLoading: false });
            notification.error({
              message: translate('guestlink.smtpError'),
            });
          })
      }
    },

    onCheckConnection: () => {
      updateState({ isLoading: true });

      API.checkSmtpConnection({ 
        host: state.host,
        port: state.port,
        security_layer: state.security_layer,
        user: state.user,
        password: state.password,
       })
        .then(_ => {
          updateState({ isLoading: false })
          notification.success({
            message: translate('guestlink.checkConnectionSuccess'),
          });
        }).catch(err => {
          console.warn(err);
          updateState({ isLoading: false });
          notification.error({
            message: translate('guestlink.checkConnectionError'),
          });
        });
        },

          setShowTestEmailModal: (show) => {
            const { isLoading, showSendTestEmailModal, testEmailAddress, isSendingTestEmail, ...smtpConfig } = state;

            const {
              host,
              port,
              security_layer,
              user,
              password,
              default_sender
            } = smtpConfig;

            if (!host || !port || !security_layer || !user || !password || !default_sender) {
              notification.error({
                message: translate('guestlink.requiredFieldsError'),
              });
              updateState({ isLoading: false });
            } else {
              updateState({ showSendTestEmailModal: show });
            }
          },

          onSendTestEmail: () => {
            if (!state.testEmailAddress || !validateEmail(state.testEmailAddress.trim())) {
              notification.error({
                message: translate('mailTemplates.errorTestEmailValidation'),
              });
            } else {
              actions.doSendTestEmail();
            }
          },

          doSendTestEmail: () => {
            updateState({ isSendingTestEmail: true });

            const testText = `${getSelectedHotel()?.name} | SMTP Test email`;

            const data = {
              from: state.default_sender,
              recipients: [state.testEmailAddress],
              subject: testText,
              body: testText,
              tags: ['guestlink-tests'],
              hotelSlug: getSelectedHotel()?.slug,
              smtpConfig: { 
                host: state.host,
                port: state.port,
                security_layer: state.security_layer,
                user: state.user,
                password: state.password,
                default_sender: state.default_sender,
              },
            };

            API.sendTestEmail({ data })
              .then(_ => {
                updateState({ isSendingTestEmail: false, showSendTestEmailModal: false });
                notification.success({
                  message: translate('mailTemplates.successTestEmail'),
                });
              })
              .catch(error => {
                updateState({ isSendingTestEmail: false });
                notification.error({
                  message: translate('mailTemplates.errorTestEmail'),
                });
              });
          },

          setTestEmailAddress: email => {
            updateState({ testEmailAddress: email });
          },

          setField: (fieldKey, value) => {
            updateState({ [fieldKey]: value });
          },
  };

    function updateState(object) {
      setState(previousState => ({ ...previousState, ...object }));
}

return { state, actions };
}
