import Colors from '../../constants/Colors';

export default {
  editorContainer: {
    display: 'flex',
    flexDirection: 'column',
    // borderTop: `1px solid darkgray`,
    // borderBottom: `1px solid darkgray`,
    borderRadius: 4,
    overflow: 'hidden',
  },

  textContainer: {
    fontWeight: 'bold',
    color: Colors.darkText,
    padding: 8,
    fontSize: 14,
    // borderRight: `1px solid darkgray`,
    // borderLeft: `1px solid darkgray`,
  },

  header: {
    backgroundColor: Colors.headerBackground,
    padding: 8,
    display: 'flex',
    gap: 4,
  },

  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: 8,
    marginTop: 8,
  },

  saveButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 8,
  },

  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: Colors.headerBackground,
    alignItems: 'center',
    paddingRight: 8,
  },

  labelContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    alignItems: 'center',
    flex: 1,
    width: '100%',
  },
};
