import constants from './Guestlink.constants';
import { getEmailScheduleCode} from 'utils/TextUtils';


const { EMAIL_SCHEDULE } = constants;

function getTemplateFromTypeAndLang(emailScheduleCode, lang) {
  console.log(emailScheduleCode);
  const typePath = getTypePath(emailScheduleCode); 

  
  try {
    if (emailScheduleCode === 'error' || lang.includes('upsell')) {
      const template = require(`./templates/body/empty-template.js`);
      return template?.value || '';
    } else {
      const template = require(`./templates/body/${lang}/${typePath}.js`);
      return template?.value || '';
    }
  } catch (error) {
    return '';
  }
}


function getSubjectFromTypeAndLang(emailType, scheduleValue, lang) {
  
  try {
    const typePath = getSubjectTypePath(emailType, scheduleValue);

    if (emailType.includes('upsell') || scheduleValue.includes('upsell')) return '';

    const prestaySubject = require(`./templates/subject/guestlink.${typePath}.default-subject.js`);

    return prestaySubject[lang] || prestaySubject['en'];
  } catch (error) {
    return '';
  }
}

function getSubjectTypePath(emailType, scheduleValue) {
  switch (scheduleValue) {
    case EMAIL_SCHEDULE.VALUE.PARTIAL:
      if (emailType === EMAIL_SCHEDULE.TYPE.PRESTAY) return 'checkin-partial';
      case EMAIL_SCHEDULE.VALUE.FINAL:
        if (emailType === EMAIL_SCHEDULE.TYPE.PRESTAY) return 'checkin-final';
        else if (emailType === EMAIL_SCHEDULE.TYPE.INTRASTAY) return 'checkout';
          default:
            if (emailType === EMAIL_SCHEDULE.TYPE.PRESTAY) return 'before-entrance-all';
            else if (emailType === EMAIL_SCHEDULE.TYPE.POSTSTAY) return 'poststay';
            else if (emailType === EMAIL_SCHEDULE.TYPE.INTRASTAY) return 'checkout-before-departure-all';
          }
}


function getTypePath(emailScheduleCode) {

  
  const template = {
    "pre-stay-checkin-partial": "checkin-partial",
    "pre-stay-checkin-final": "checkin-final",
    "pre-stay-door-opening-0-hour": "door-opening-all",
    "pre-stay-door-opening-on-checkin": "door-opening-onCheckin",
    "pre-stay-upsell-hotel-requested": "upsell-hotel-requested",
    "pre-stay-upsell-hotel-rejected": "upsell-hotel-rejected",
    "pre-stay-upsell-hotel-accepted": "upsell-hotel-accepted",
    "intra-stay-checkout-final": "checkout-final",
    "intra-stay-before-departure-24-hour": "checkout-departure-24-hour",
    "intra-stay-on-departure-0-hour": "departure-0-hour",
  };


  if (getPrestayTemplates(emailScheduleCode)) {
    return "before-entrance-all";
  }

  return template[emailScheduleCode] || "empty";
}

function getPrestayTemplates(emailScheduleCode) {
  return [
    "pre-stay-before-entrance-24-hour",
    "pre-stay-before-entrance-48-hour",
    "pre-stay-before-entrance-72-hour",
    "pre-stay-before-entrance-5-day",
    "pre-stay-before-entrance-1-week",
    "pre-stay-before-entrance-2-week",
    "pre-stay-before-entrance-30-day",
  ].includes(emailScheduleCode);
}



export { getSubjectFromTypeAndLang, getTemplateFromTypeAndLang };
