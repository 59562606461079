import React, { useContext, useRef } from 'react';
import styles from './Forms.styles.js';
import useFormsHook from './Forms.hook.js';
import { AppContext } from 'App.context.js';
import { Select } from 'antd';
import DefaultLayout from 'Components/DefaultLayout/DefaultLayout.jsx';
import SaveButton from 'Components/common/SaveButton/SaveButton.jsx';
import GuestForms from './GuestForms/GuestForms.jsx';
import DynamicForms from './DynamicForms/DynamicForms.jsx';
import Loading from 'Components/common/Loader/Loading.jsx';

export default function Forms() {
  const {
    appState,
    appActions: { translate, getSelectedHotel },
  } = useContext(AppContext);

  const { state, actions } = useFormsHook();
  const formRef = useRef();

  const languagesMenu = state.languages?.map(language => {
    return (
      <Select.Option key={language?.code} value={language?.code}>
        {`  ${language?.label}`}
      </Select.Option>
    );
  });

  return (
    <DefaultLayout
      singleBox
      title={translate('layout.forms')}
      contentHeader={
        <div style={styles.header}>
          <div>
            {state.languages && (
              <Select
                style={{ marginRight: 8 }}
                defaultValue={state.languages[0].code}
                value={state.selectedLanguage}
                onChange={option => actions.onLanguageSelected(option)}
              >
                {languagesMenu}
              </Select>
            )}
            {translate('guestTable.languageSelector')}
          </div>

          {appState.hotelList && appState.hotelList?.length && (
            <div style={styles.footer}>
              <SaveButton
                text={translate('save')}
                action={applyToAllHotels => {
                  if (formRef.current) {
                    formRef.current.saveClicked(applyToAllHotels);
                  }
                }}
                showPopConfirm
              />
              {state.isLoading && (
                <div style={{ marginLeft: 16 }}>
                  <Loading size="small" />
                </div>
              )}
            </div>
          )}
        </div>
      }
    >
      <div style={styles.container}>
        {state.isDynamicForm ? (
          state.form ? (
            <DynamicForms
              key={getSelectedHotel()?.key}
              formRef={formRef}
              isLoading={state.isLoading}
              selectedLanguage={state.selectedLanguage}
              form={state.form}
              onSave={actions.saveForm}
              languages={state.languages}
            />
          ) : (
            <div style={styles.dynamicEmpty}>
              <Loading />
            </div>
          )
        ) : (
          <GuestForms
            formRef={formRef}
            isLoading={state.isLoading}
            selectedLanguage={state.selectedLanguage}
            selectedHotelId={getSelectedHotel()?.key}
            languages={state.languages}
            onSave={actions.saveForm}
          />
        )}
      </div>
    </DefaultLayout>
  );
}
