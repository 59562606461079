import React from 'react';
import { Modal } from 'antd';

export default function AppModal({
  show,
  title,
  description,
  okText,
  okAction,
  cancelText,
  cancelAction,
  closable,
}) {
  return (
    <Modal
      title={title}
      visible={show}
      onOk={() => okAction && okAction()}
      onCancel={() => cancelAction && cancelAction()}
      cancelText={cancelText}
      okText={okText}
      closable={closable}
    >
      <p>{description}</p>
    </Modal>
  );
}
