module.exports = {
  value: `<div style="background-color: #f1f4f8;">
    <!--[if mso | IE]>
    <table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600">
        <tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
    <![endif]-->
    <div style="margin: 0 auto; max-width: 600px;">
        <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width: 100%;">
            <tbody>
                <tr>
                    <td style="direction: ltr; font-size: 0px; padding: 0; text-align: center;">
                        <!--[if mso | IE]></td></tr></table><![endif]-->
                        <br>
                    </td>
                </tr>
            </tbody>
        </table>
        <br>
    </div>
    <div style="width: 100%; max-width: 600px; margin: 0 auto; background-color: #ffffff; padding: 40px 20px; text-align: center; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);">
        <div class="header" style="margin-bottom: 20px;">
            <img src="https://www.civitfun.com/blog/wp-content/uploads/2024/05/Check-in.png" style="width: 64px; height: auto;">
        </div>
        <div class="title" style="font-size: 30px; line-height: 1.5; color: #000c30; margin-bottom: 10px; font-family: Montserrat, 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif;">Check-in confermato</div>
        <div class="title" style="font-size: 16px; color: #000c30; margin-bottom: 10px; margin-left: 25px; margin-right: 25px; font-family: Montserrat, 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif;">Grazie per aver scelto [hotel_name]. Di seguito sono riportati i dettagli della tua prenotazione e i documenti firmati al check-in.</div>
        <div class="title" style="font-size: 12px; color: #000c30; margin-bottom: 30px; font-family: Montserrat, 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif;"><strong>Localizzatore:</strong> [reference_number]</div>
        <div class="dates" style="display: flex; justify-content: center; align-items: center; margin-bottom: 30px;">
            <div class="date" style="font-size: 16px; color: #000c30; width: 50%; font-family:Montserrat,'Trebuchet MS','Lucida Grande','Lucida Sans Unicode','Lucida Sans',Tahoma,sans-serif;">
                <strong style="display: block; margin-bottom: 5px;"><img src="https://www.civitfun.com/blog/wp-content/uploads/2024/05/calendar-7.png" style="height: 10px; border: 0; width: 10px; margin-right: 5px;">Data di arrivo</strong>
                [entrance_date]
            </div>
            <div class="divider" style="border-left: 1px solid #d0d0d0; height: 50px; margin: 0 20px;"></div>
            <div class="date" style="font-size: 16px; color: #000c30; width: 50%; font-family: Montserrat, 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif;">
                <strong style="display: block; margin-bottom: 5px;"><img src="https://www.civitfun.com/blog/wp-content/uploads/2024/05/calendar-7.png" style="height: 10px; border: 0; width: 10px; margin-right: 5px;">Data di partenza</strong>
                [departure_date]
            </div>
        </div>
        <div class="title" style="font-size: 18px; line-height: 1.5; color: #000c30; margin-bottom: 10px; font-family: Montserrat, 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif;">Non vediamo l'ora di darti il benvenuto!</div>
    </div>
    <div style="margin: 0 auto; max-width: 600px;">
        <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width: 100%;">
            <tbody>
                <tr>
                    <td style="direction: ltr; font-size: 0px; padding: 0; text-align: center;">
                        <!--[if mso | IE]></td></tr></table><![endif]-->
                        <br>
                    </td>
                </tr>
            </tbody>
        </table>
        <br>
    </div>
</div>`,
};
