import React, { useContext } from 'react';
import { Table } from 'antd';
import { Checkbox } from 'antd';
import { AppContext } from '../../../../App.context';
import Colors from 'constants/Colors';
import EditableInput from './EditableInput/EditableInput';
import GuestFormFieldState from 'Components/Dashboard/Forms/GuestForms/GuestFormFieldState';

export default function GuestTable({
  isLoading,
  data,
  onFieldStateChecked,
  onFieldRequiredChecked,
  selectedLanguage,
  onFieldTextChanged,
  supportedLanguages,
}) {
  const {
    appActions: { translate },
  } = useContext(AppContext);

  const columns = [
    {
      title: translate('guestTable.fieldCol'),
      dataIndex: 'name',
      key: 'name',
      render: (_, item) => {
        const defaultText = item?.defaultTexts?.find(text => text.lang == selectedLanguage);
        const formText = item?.formTexts?.find(formText => formText.lang == selectedLanguage);

        return item.name.includes('uploadDocument') ? (
          getEditableInput({
            item,
            onValueChanged: onFieldTextChanged,
            supportedLanguages,
            formText,
            defaultText,
          })
        ) : (
          <div>{formText?.value}</div>
        );
      },
    },
    {
      title: null,
      dataIndex: 'desc',
      key: 'desc',
      render: (_, item) => {
        if (item?.desc) {
          return <div style={{ color: Colors.red }}>{item.desc}</div>;
        } else return null;
      },
    },
    {
      title: translate('guestTable.visibleCol'),
      dataIndex: 'visible',
      key: 'visible',
      align: 'center',
      render: (_, item) => {
        return (
          <Checkbox
            disabled={item?.mandatory}
            checked={item?.state == GuestFormFieldState.ACTIVE}
            onClick={() =>
              onFieldStateChecked({
                id: item?.id,
                fieldHotelId: item?.fieldHotelId,
              })
            }
          />
        );
      },
    },
    {
      title: translate('guestTable.mandatoryCol'),
      dataIndex: 'required',
      key: 'required',
      align: 'center',
      render: (_, item) => {
        return item.onlyItaly ? null : (
          <Checkbox
            disabled={item?.mandatory}
            checked={item?.required == 1}
            onClick={() =>
              onFieldRequiredChecked({ id: item?.id, fieldHotelId: item?.fieldHotelId })
            }
          />
        );
      },
    },
  ];

  return (
    <div>
      <Table loading={isLoading} columns={columns} dataSource={data} pagination={false} />
    </div>
  );
}

function getEditableInput({ item, onValueChanged, formText, defaultText, supportedLanguages }) {
  const isCustomText = formText?.value !== defaultText?.value || formText?.value == null;

  let hasMissingTranslations = false;
  if (!supportedLanguages || !supportedLanguages.length) return;
  for (const lang of supportedLanguages?.map(item => item.code)) {
    const formText = item?.formTexts?.find(formText => formText.lang == lang);
    const defaultText = item?.defaultTexts?.find(text => text.lang == lang);

    if (!formText || formText?.value === defaultText?.value) {
      hasMissingTranslations = true;
    }
  }

  return (
    <EditableInput
      item={item}
      hint={defaultText?.value}
      value={isCustomText ? formText?.value : ''}
      onValueChanged={onValueChanged}
      hasMissingTranslations={hasMissingTranslations}
    />
  );
}
