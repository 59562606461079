module.exports = {
    value: `<p data-pm-slice="1 1 []">Dear [hotel_name],</p>
<p>
  I'm pleased to inform you that one of your guests has requested a room
  upgrade. Below you will find the relevant information:
</p>
<ul class="ak-ul">
  <li>
    <p><strong>Upselling ID:</strong> [upsell_id]</p>
  </li>
  <li>
    <p><strong>Upselling Request Date:</strong> [upsell_request_date]</p>
  </li>
  <li>
    <p><strong>Upselling State:</strong> [upsell_state]</p>
  </li>
  <li>
    <p><strong>Booking Identifier:</strong> [booking_identifier]</p>
  </li>
  <li>
    <p>
      <strong>Reservation Start and End Date:</strong> [entrance_date] -
      [departure_date]
    </p>
  </li>
  <li>
    <p><strong>Original Room Type:</strong> [original_room_type]</p>
  </li>
  <li>
    <p><strong>Requested Room Type:</strong> [requested_room_type]</p>
  </li>
  <li>
    <p><strong>Upselling Price:</strong> [upsell_price]</p>
  </li>
  <li>
    <p><strong>Payment Status:</strong> [payment_status]</p>
  </li>
</ul>
<p>
  Please review this request in the Civitfun panel
  (https://dashboard.civitfun.com/upsell) and confirm if it's possible to
  fulfill the room category upgrade for your guest.
</p>
<p>Best regards,</p>
<p>Team Civitfun</p>
`,
  };