export default {
  EMAIL_SCHEDULE: {
    TYPE: {
      PRESTAY: 'pre-stay',
      INTRASTAY: 'intra-stay',
      POSTSTAY: 'post-stay',
    },
    VALUE: {
      PARTIAL: 'partial',
      FINAL: 'final',
      ONE_DAY: '24',
      TWO_DAYS: '48',
      THREE_DAYS: '72',
      FIVE_DAYS: '5',
      ONE_WEEK: '1',
      TWO_WEEKS: '2',
    },
    SCHEDULE: {
      CHECKIN: 'checkin',
      CHECKOUT: 'checkout',
      BEFORE_ENTRANCE: 'before-entrance',
      ON_ENTRANCE: 'on-entrance',
      AFTER_ENTRANCE: 'after-entrance',
      BEFORE_DEPARTURE: 'before-departure',
      ON_DEPARTURE: 'on-departure',
      AFTER_DEPARTURE: 'after-departure',
    },
    CATEGORY: {
      TRANSACTIONAL: 'transactional',
      MARKETING: 'marketing',
    },
  },
};
