import React, { useContext, useImperativeHandle } from 'react';
import styles from './DynamicForms.styles.js';
import useDynamicFormsHook from './DynamicForms.hook.js';
import { Table } from 'antd';
import './style.css';
import DynamicFormsRowType from './DynamicFormsRowType';
import DynamicFormsRow from './DynamicFormsRow/DynamicFormsRow.jsx';
import { AppContext } from 'App.context.js';

const { HEADER, SECTION, FIELD, FIELD_GROUP } = DynamicFormsRowType;

export default function DynamicForms({
  formRef,
  isLoading,
  form,
  onSave,
  selectedLanguage,
  languages,
}) {
  const {
    appActions: { translate },
  } = useContext(AppContext);
  const { state, actions } = useDynamicFormsHook(form, selectedLanguage);

  useImperativeHandle(formRef, () => ({ saveClicked }));

  const saveClicked = applyToAllHotels => {
    onSave(state.form, applyToAllHotels);
  };

  const columns = [
    {
      title: 'Field',
      dataIndex: 'rowType',
      fixed: 'left',
      width: '20%',
      render: (rowType, item) => {
        switch (rowType) {
          case FIELD:
            return item.field.includes('uploadDocument')
              ? actions.getEditableInput({
                  item: actions.getFieldLiteral(item.literals),
                  onValueChanged: actions.onFieldTextChanged,
                  supportedLanguages: languages,
                  allLiterals: item.literals,
                  field: item.field,
                })
              : actions.getFieldLiteral(item.literals)?.value;
          case FIELD_GROUP:
          case SECTION:
            return (
              <div style={{ ...styles.section }}>
                {translate(`dynamicForms.group.${item.field}`)}
              </div>
            );
          case HEADER:
          default:
            return null;
        }
      },
    },
    {
      dataIndex: 'rowType',
      render: (rowType, item) => {
        return (
          <div style={styles.valueContainer}>
            <DynamicFormsRow rowType={rowType} item={item} setFieldValue={actions.setFieldValue} />
          </div>
        );
      },
    },
  ];

  return (
    <div className={'dynamicForm'}>
      <Table
        showHeader={false}
        columns={columns}
        dataSource={state.form?.fields}
        isLoading={isLoading || state.isLoading}
        pagination={false}
        scroll={{
          x: 10,
        }}
      />
    </div>
  );
}
