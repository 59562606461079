module.exports = {
  value: `<p>
    Hello [guest_first_name].&nbsp;Thank you for choosing [hotel_name]. We really want to receive you.
    Here are the details of your reservation and the attached documents: </p>
<p>
    Booking confirmation number:&nbsp;[reference_number] </p>
<ul>
    <li> Arrival date:&nbsp;[entrance_date] </li>
    <li>
        Departure date:&nbsp;[departure_date] </li>
    <li> Address:&nbsp;[address] </li>
</ul>
<p>
    If you need to make changes or require assistance please call [hotel_phone]&nbsp;or email us at [CIF_business_email].
    We look forward to welcoming you to [CIF_city]&nbsp;soon!&nbsp; </p>
<p> <img src = "[hotel_logo]" width = "200" height = "100"> <br> </p>
<p> [hotel_name] </p>`,
};
