import EditableInput from 'Components/Dashboard/CheckIn/GuestTable/EditableInput/EditableInput';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import GuestFormFieldState from '../GuestForms/GuestFormFieldState';
import DynamicFormsRowType from './DynamicFormsRowType';

const { HEADER, SECTION, FIELD, FIELD_GROUP } = DynamicFormsRowType;

export default function useDynamicFormsHook(initialFormData, selectedLanguage) {
  const [state, setState] = useState({
    isLoading: true,
    form: null,
  });

  useEffect(() => {
    updateState({
      isLoading: false,
      form: {
        fields: actions.parseDynamicFormFields(initialFormData),
        modifiedFieldHotels: [],
        customTranslations: [],
      },
    });
  }, []);

  const actions = {
    parseDynamicFormFields: data => {
      let addedGroups = [];
      let addedOther = false;
      let addedUpload = false;

      const guestTypes = Object.keys(data).map(type => {
        return type;
      });

      let parsedByField = actions.groupDataByField(data, guestTypes);
      parsedByField = actions.orderDocumentFields(parsedByField);

      const allGroups = [...new Set(parsedByField.map(item => item.group))];
      const sortedFields = [];

      for (const group of allGroups) {
        const groupFields = parsedByField.filter(f => f.group === group);
        sortedFields.push(...groupFields);
      }

      parsedByField = sortedFields;

      for (const [index, field] of parsedByField.entries()) {
        if (field.group && !addedGroups.includes(field.group)) {
          parsedByField.splice(index, 0, {
            field: field.group,
            rowType: FIELD_GROUP,
            literals: [
              { lang: 'es', value: field.group },
              { lang: 'en', value: field.group },
            ],
            values: field.values,
            guestTypes: field.guestTypes,
          });

          addedGroups.push(field.group);
        } else if (!field.group && !addedOther) {
          console.log(field);
          const randomSection = {
            field: 'other',
            rowType: SECTION,
            guestTypes: guestTypes,
          };

          parsedByField.splice(index, 0, randomSection);

          addedOther = true;
        } else if (
          !field.group &&
          addedOther &&
          !addedUpload &&
          field.field.includes('uploadDocument')
        ) {
          const uploadDocumentSection = {
            field: 'uploadDocument',
            rowType: SECTION,
            guestTypes: guestTypes,
          };

          parsedByField.splice(index, 0, uploadDocumentSection);

          addedUpload = true;
        }
      }

      return [
        {
          rowType: HEADER,
          guestTypes: guestTypes,
        },
        ...parsedByField,
      ];
    },

    groupDataByField: (data, guestTypes) => {
      const groupedData = [];

      // Iterate over each guest type
      for (const guestType in data) {
        const guests = data[guestType];
        if (guests) {
          // Iterate over each guest's fields
          for (const guest of guests) {
            const fieldHotelId = guest.fieldHotelId;
            const fieldName = guest.name;
            const required = guest.required;
            const mandatory = guest.mandatory;
            const state = guest.state;

            // Check if the field already exists in the groupedData
            const existingField = groupedData.find(field => field.field === fieldName);
            const value = {
              fieldHotelId,
              guest: guestType,
              required,
              mandatory,
              state,
              fieldId: guest.fieldId,
            };

            if (existingField) {
              // Add guest type info to the existing field
              existingField.values.push(value);
            } else {
              // Create a new field and add the guest type info
              const newField = {
                field: fieldName,
                rowType: FIELD,
                literals: guest.formTexts,
                guestTypes: guestTypes,
                group: guest?.group || null,
                values: [value],
              };
              groupedData.push(newField);
            }
          }
        }
      }

      return groupedData;
    },

    getFieldLiteral: literals => {
      const literal = literals.find(l => l.lang === selectedLanguage);
      return literal;
    },

    orderDocumentFields: fields => {
      const uploadFields = [];
      const otherFields = [];

      for (const field of fields) {
        if (field.field.includes('uploadDocument')) {
          uploadFields.push(field);
        } else {
          otherFields.push(field);
        }
      }

      return otherFields.concat(uploadFields);
    },

    setFieldValue: ({ guestType, fieldName, required = null, active = null, rowType }) => {
      let formCopy = JSON.parse(JSON.stringify(state.form));

      const updateField = field => {
        formCopy.modifiedFieldHotels = [
          field.values.find(v => v.guest === guestType)?.fieldHotelId,
          ...(formCopy?.modifiedFieldHotels || []),
        ];

        const value = field.values.find(v => v.guest === guestType);
        if (!value) return;

        if (required !== null) {
          if (required === 1) {
            value.state = GuestFormFieldState.ACTIVE;
          }
          value.required = required;
        } else if (active !== null) {
          value.state = active;

          if (active === 'disabled') {
            value.required = 0;
          }
        }
      };

      if (required === 1) {
        active = GuestFormFieldState.ACTIVE;
      }

      if (rowType === FIELD) {
        const field = formCopy.fields.find(f => f.field === fieldName);
        updateField(field);
      } else if (rowType === FIELD_GROUP) {
        const groupFields = formCopy.fields.filter(f => f?.group === fieldName);

        for (const field of groupFields) {
          updateField(field);
        }

        const field = formCopy.fields.find(f => f.field === fieldName);
        updateField(field);
      }

      updateState({ form: formCopy });
    },

    getEditableInput: ({ item, onValueChanged, allLiterals, supportedLanguages, field }) => {
      item.field = field;

      const hasAllTranslations = supportedLanguages.every(language => {
        const savedTranslation = state.form.customTranslations.find(
          t => t.lang === language.code && t.field === field
        );

        if (savedTranslation) return true;

        const text = allLiterals.find(l => l.lang === language.code);
        return text && text.customTranslationId;
      });

      return (
        <div style={{ padding: 8 }}>
          <EditableInput
            item={item}
            hint={item?.defaultText}
            value={item?.value === item?.defaultText ? '' : item?.value}
            onValueChanged={onValueChanged}
            hasMissingTranslations={!hasAllTranslations}
          />
        </div>
      );
    },

    onFieldTextChanged: data => {
      const formCopy = JSON.parse(JSON.stringify(state.form));
      const literal = formCopy.fields
        .flatMap(field => field.literals)
        .find(literal => literal?.id === data.id);

      if (literal) {
        literal.value = data.customText;
      }

      const savedTranslation = formCopy.customTranslations.find(
        t => t.lang === selectedLanguage && t.field === data.field
      );

      if (savedTranslation) {
        savedTranslation.value = data.customText;
      } else {
        formCopy.customTranslations.push({
          customTranslationId: data?.customTranslationId,
          field: data?.field,
          lang: selectedLanguage,
          value: data.customText,
        });
      }

      updateState({ form: formCopy });
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}
