module.exports = {
    value: `<p data-pm-slice="1 1 []">Estimado [hotel_name],</p>
<p>
  Me complace informarle que se ha realizado una venta adicional de habitación
  para uno de sus huéspedes. A continuación, encontrará los detalles relevantes:
</p>
<ul class="ak-ul">
  <li>
    <p><strong>ID de Venta Adicional:</strong> [upsell_id]</p>
  </li>
  <li>
    <p>
      <strong>Fecha de Solicitud de Venta Adicional:</strong>
      [upsell_request_date]
    </p>
  </li>
  <li>
    <p><strong>Estado de la Venta Adicional:</strong> [Confirmado]</p>
  </li>
  <li>
    <p><strong>Identificador de Reserva:</strong> [booking_identifier]</p>
  </li>
  <li>
    <p>
      <strong>Fecha de Inicio y Fin de la Reserva:</strong> [entrance_date] -
      [departure_date]
    </p>
  </li>
  <li>
    <p><strong>Tipo de Habitación Original:</strong> [original_room_type]</p>
  </li>
  <li>
    <p><strong>Tipo de Habitación Solicitado:</strong> [requested_room_type]</p>
  </li>
  <li>
    <p><strong>Precio de la Venta Adicional:</strong> [upsell_price]</p>
  </li>
  <li>
    <p><strong>Estado de Pago:</strong> [payment_status]</p>
  </li>
</ul>
<p>
  Puede revisar todos los detalles en el panel de Civitfun
  (https://dashboard.civitfun.com/upsell).
</p>
<p>Atentamente,</p>
<p>Equipo Civitfun</p>
`,
  };