import React, { useContext } from 'react';
import styles from './PoliceReportList.styles.js';
import usePoliceReportListHook from './PoliceReportList.hook.js';
import DefaultLayout from 'Components/DefaultLayout/DefaultLayout.jsx';
import { AppContext } from 'App.context.js';
import BaseTable from 'Components/common/BaseTable/BaseTable.jsx';
import PoliceReportCaption from '../PoliceReportCaption/PoliceReportCaption.jsx';
import DotIndicator from 'Components/common/DotIndicator/DotIndicator.jsx';
import Modal from 'antd/lib/modal/Modal';
import PoliceReportDetail from '../PoliceReportDetail/PoliceReportDetail.jsx';
import { capitalize } from 'utils/TextUtils.js';
import { Button, DatePicker } from 'antd';
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import moment from 'moment';
import AppInput from 'Components/common/AppInput/AppInput.jsx';
import AppInputTypes from 'Components/common/AppInput/AppInputTypes.js';
import AppButton from 'Components/common/Forms/AppButton/AppButton.jsx';
import Colors from 'constants/Colors.js';

const { RangePicker } = DatePicker;

export default function PoliceReportList() {
  const { state, actions } = usePoliceReportListHook();
  const {
    appActions: { translate, getSelectedHotel },
  } = useContext(AppContext);

  const stateRow = {
    title: translate('policeReport.statusCol'),
    dataIndex: 'status',
    key: 'status',
    sorter: false,
    filterMultiple: false,
    filters: [
      { text: 'Ok', value: 'ok' },
      { text: 'Error', value: 'error' },
      { text: 'Pending', value: 'pending' },
    ],
    defaultFilteredValue: state.filters?.status ? [state.filters?.status] : [],

    render: (status, object) => {
      const statusText =
        status === 'error'
          ? actions.parseError(object.error) || translate(`policeReport.status.${object.status}`)
          : translate(`policeReport.status.${object.status}`);
      return (
        <div style={styles.statusContainer}>
          <DotIndicator color={actions.getColorForStatus(object.status)} />
          <div>{statusText}</div>
        </div>
      );
    },
  };

  const columns = [
    {
      title: translate('policeReport.batchCol'),
      dataIndex: 'batch',
      key: 'batch',
      sorter: false,
      searchable: true,
      defaultFilteredValue: state.filters?.batch ? [state.filters?.batch] : [],
    },
    {
      title: translate('policeReport.dateCol'),
      dataIndex: 'entrance',
      key: 'entrance',
      sorter: false,
      render: (date, item) => {
        return <div>{moment(date).format('DD-MM-YYYY')}</div>;
      },
    },
    // {
    //   title: translate('policeReport.hotelCol'),
    //   dataIndex: 'hotel',
    //   key: 'hotel',
    //   sorter: false,
    //   render: (_, object) => {
    //     return <div>{capitalize(getSelectedHotel().value)}</div>;
    //   },
    // },
    {
      title: translate('policeReport.contractCol'),
      dataIndex: 'reference',
      key: 'reference',
      sorter: false,
      searchable: true,
      defaultFilteredValue: state.filters?.reference ? [state.filters?.reference] : [],
    },

    {
      title: translate('policeReport.guestNameCol'),
      dataIndex: 'name',
      key: 'name',
      sorter: false,
      searchable: true,
      defaultFilteredValue: state.filters?.guestName ? [state.filters?.guestName] : [],
      render: value => {
        return <div>{capitalize(value)}</div>;
      },
    },
    {
      title: translate('policeReport.guestSurnameCol'),
      dataIndex: 'surname',
      key: 'surname',
      sorter: false,
      searchable: true,
      render: value => {
        return <div>{capitalize(value)}</div>;
      },
    },
    {
      title: translate('policeReport.actionCol'),
      dataIndex: null,
      key: null,
      align: 'center',
      width: '5%',
      render: (_, object) => {
        return (
          <Button
            type="secondary"
            icon={<EyeOutlined />}
            onClick={() => actions.onRowClicked(object)}
          />
        );
      },
    },
  ];

  if (state.enableFilters) {
    columns.unshift(stateRow);
  }

  return (
    <DefaultLayout singleBox title={translate('bookingList.title')} enableAllHotels={false}>
      {state.suscription ? (
      <div style={styles.container}>
        <div style={styles.buttonContainer}>
          {state.enableFilters ? <PoliceReportCaption /> : <div />}

          {/*
            <RangePicker
              defaultValue={state.datesRange}
              onChange={actions.onDateChange}
              disabled={state.isLoading}
            /> 
          */}
          <div style={styles.header}>
            <div style={styles.datePicker}>
              <AppInput
                value={state.date}
                onChange={(_, date) => actions.onDateSet(date)}
                type={AppInputTypes.DATE}
                disabled={state.isLoading}
                bgColor={Colors.white}
              />
            </div>
            {/* <AppButton
              text={translate('policeReport.download')}
              icon={<DownloadOutlined />}
              action={actions.downloadPoliceReports}
            /> */}
          </div>
        </div>
        <div style={styles.whiteBackground}>
          <BaseTable
            columns={columns}
            data={state.dataSource}
            loading={state.isLoading}
            onChange={filters => actions.onChange(filters)}
            currentPage={state.dataSource.page}
          />
        </div>
      </div>
      ) : (
        <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                backgroundColor: Colors.white,
                borderRadius: 16,
                padding: 16,
                maxWidth: 600,
              }}
            >
              <div style={{ fontWeight: 'bold' }}>
                {translate('policeReport.missingSuscription.title')}
              </div>
              <div>{translate('policeReport.missingSuscription.description')}</div>
              {state.sendSuscriptionRequestEnabled && (
                <div style={{ display: 'flex', justifyContent: 'right', marginTop: 8 }}>
                  <AppButton
                    text={translate('policeReport.missingSuscription.send')}
                    action={() => actions.requestGuestlinkFunctionality()}
                  />
                </div>
              )}
            </div>
          </div>
        )}
    </DefaultLayout>
  );
}
