import React, { useContext } from 'react';
import ContentBox from '../../common/ContentBox/ContentBox';
import DefaultLayout from '../../DefaultLayout/DefaultLayout';
import { AppContext } from '../../../App.context';
import styles from './LegalDocuments.styles';
import useLegalDocumentsHook from './LegalDocuments.hook';
import Loading from 'Components/common/Loader/Loading';
import TextEditor from 'utils/TextEditor/TextEditor';
import { Select } from 'antd';
import { GlobalOutlined, FileSearchOutlined, InfoCircleOutlined } from '@ant-design/icons';
import InfoView from 'Components/common/InfoView/InfoView';
import SupportedLegalTypes from './SupportedLegalTypes';

export default function LegalDocuments() {
  const {
    appActions: { translate },
  } = useContext(AppContext);
  const { state, actions } = useLegalDocumentsHook();

  const languagesMenu = state.languages?.map(language => {
    return (
      <Select.Option key={language?.code} value={language?.code}>
        {state.selectedLanguage == language?.code && <GlobalOutlined />}
        {`  ${language?.label}`}
      </Select.Option>
    );
  });

  return (
    <DefaultLayout
      contentHeader={
        <div style={styles.header}>
          <div style={styles.langSelectorContainer}>
            {state.languages && (
              <Select
                value={state.selectedLanguage}
                style={styles.selector}
                onChange={option => actions.onLanguageSelected(option)}
              >
                {languagesMenu}
              </Select>
            )}
            {translate('legalDocuments.langSelectorHint')}
          </div>
        </div>
      }
    >
      <ContentBox noPadding title={translate('layout.documents')}>
        {!(!state.isLoading && state.legalDocuments) ? (
          <div style={{ display: 'flex', justifyConten: 'center' }}>
            {state.isLoading ? (
              <Loading />
            ) : (
              <InfoView text={translate('emptyHotelListError')} icon={<InfoCircleOutlined />} />
            )}
          </div>
        ) : (
          <div style={styles.editorContainer}>
            {state.legalDocuments.map((legalDocumentSet, index) => {
              const mainLegalDocument = legalDocumentSet?.find(
                document => document.lang == state.selectedLanguage
              );
              const typeConfig = SupportedLegalTypes.find(
                legalType => legalType.id == mainLegalDocument.type
              );
              const labelSet = state.legalDocuments.find(
                set => set[0].type == `${mainLegalDocument.type}-label`
              );
              const labelDocument = labelSet?.find(
                document => document.lang == state.selectedLanguage
              );

              return typeConfig.isVisible ? (
                <TextEditor
                  key={index}
                  isFirst={index == 0}
                  type={mainLegalDocument.type}
                  onPreviewPressed={actions.onPreviewPressed}
                  onSavePressed={actions.onSavePressed}
                  documentDefaultValue={mainLegalDocument.value}
                  labelDefaultValue={labelDocument?.value}
                  supportedTypes={SupportedLegalTypes}
                  selectedLanguage={state.selectedLanguage}
                />
              ) : null;
            })}
          </div>
        )}
      </ContentBox>

      <ContentBox title={translate('legalDocuments.preview')}>
        {state.previewText ? (
          <div
            style={{ textAlign: 'justify', minHeight: 480 }}
            dangerouslySetInnerHTML={{ __html: state.previewText }}
          />
        ) : (
          <div style={{ minHeight: 480 }}>
            <InfoView
              text={translate('legalDocuments.previewPlaceholder')}
              icon={<FileSearchOutlined />}
            />
          </div>
        )}
      </ContentBox>
    </DefaultLayout>
  );
}
