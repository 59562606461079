export default [
  {
    name: 'configuration',
    showInProduction: true,
    children: [
      {
        path: '/hotels',
        name: 'hotels',
        showInProduction: true,
        icon: 'location',
      },
      {
        path: '/pms',
        name: 'pms',
        showInProduction: true,
        icon: 'globo',
      },
      {
        path: '/checkin-config',
        name: 'configuration',
        showInProduction: true,
        icon: 'settings',
      },
      {
        path: '/guest-forms',
        name: 'forms',
        showInProduction: true,
        icon: 'list-interface-symbol',
      },
      {
        path: '/appearence',
        name: 'appearence',
        showInProduction: true,
        icon: 'pincel-de-artista',
      },
      {
        path: '/channels',
        name: 'sales',
        showInProduction: true,
        icon: 'checked',
      },
      {
        path: '/payment',
        name: 'payment',
        showInProduction: true,
        icon: 'tarjeta-de-credito',
      },
      {
        path: '/dooropening',
        name: 'doorOpening',
        showInProduction: true,
        icon: 'puerta',
        children: [
          {
            path: '/dooropening',
            name: 'doorOpeningConfig',
            showInProduction: true,
          },
          {
            path: '/door-maintenance',
            name: 'doorMapping',
            showInProduction: true,
          },
        ],
      },
      {
        path: '/legal',
        name: 'documents',
        showInProduction: true,
        icon: 'equilibrar',
      },
      // {
      //   path: '/communication-channels',
      //   name: 'communicationChannels',
      //   showInProduction: false,
      //   icon: 'megaphone',
      // },
      {
        path: '/guestlink',
        name: 'guestlink',
        showInProduction: true,
        icon: 'guestlink',
        children: [
          {
            path: '/guestlink',
            name: 'mailTemplates',
            showInProduction: true,
          },
          {
            path: '/guestlink-config',
            name: 'smtpConfig',
            showInProduction: true,
          },
        ],
      },
      {
        path: '/paperless-push',
        name: 'paperlessPush',
        showInProduction: true,
        onlySuperUser: true,
        icon: 'paperless-push',
      },
    ],
  },
  {
    name: 'dashboard',
    showInProduction: true,
    children: [
      {
        path: '/stats',
        name: 'stats',
        showInProduction: true,
        icon: 'grafico',
      },
      {
        path: '/checkin',
        name: 'checkin',
        showInProduction: true,
        icon: 'tick',
      },
      {
        path: '/checkout',
        name: 'checkout',
        showInProduction: true,
        icon: 'checkout',
      },
      {
        path: '/booking',
        name: 'booking',
        showInProduction: true,
        icon: 'reserved',
      },
      {
        path: '/payments-list',
        name: 'paymentList',
        showInProduction: true,
        icon: 'tarjeta-de-credito',
      },
      {
        path: '/police-report',
        name: 'policeReport',
        showInProduction: true,
        icon: 'police-report',
        children: [
          {
            path: '/police-report-config',
            name: 'policeReportConfig',
            showInProduction: true,
          },
        ],
      },
      {
        path: '/marketing',
        name: 'marketing',
        showInProduction: true,
        icon: 'bullseye',
      },
      {
        path: '/upsell',
        name: 'upsell',
        showInProduction: true,
        icon: 'upsell-icon',
      },
      {
        path: '/cross-sell',
        name: 'crossSell',
        showInProduction: true,
        icon: 'upsell-icon',
      },
    ],
  },
  {
    name: 'account',
    showInProduction: true,
    children: [
      {
        path: '/account-detail',
        name: 'accountDetail',
        showInProduction: true,
        icon: 'user',
      },
      {
        path: '/users',
        name: 'users',
        showInProduction: true,
        icon: 'star',
      },
      {
        path: '/billing',
        name: 'billingConfig',
        showInProduction: true,
        icon: 'tarjeta-de-credito',
      },
      {
        path: '/support',
        name: 'support',
        showInProduction: true,
        icon: 'support',
      },
    ],
  },
];
