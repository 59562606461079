import { useContext, useState } from 'react';
import { AppContext } from '../../../../../App.context';
import Colors from '../../../../../constants/Colors';
import { priceToString } from '../../../../../utils/CurrencyUtils';
import PaymentConstants from './PaymentConstants';
import { roundAmount } from '../../../../../utils/NumberUtils';

const useCheckInPaymentHook = () => {
  const {
    appActions: { translate },
  } = useContext(AppContext);

  const [state, setState] = useState({});
  
  const actions = {
    getColumnPrice: (amount, currency) => {
      if (!amount || amount == 0) return '-';
      const price = priceToString(amount, currency);
      return price;
    },

    getTotalAmount: pricesList => {
      if (!pricesList) return null;

      const totalPrice = roundAmount(actions.getPricesSum(pricesList));
      return priceToString(totalPrice, pricesList[0]?.currency);
    },

    getTotalBalance: (charges, operations) => {
      if (!charges || !operations) return null;
      
      const totalCharges = actions.getPricesSum(charges);
      const totalOperations = actions.getPricesSum(operations);

      return priceToString(roundAmount(totalCharges - totalOperations), charges[0]?.currency);
    },

    getPricesSum: pricesList => {
      if (!pricesList) return null;
      let totalPrice = 0;
      pricesList.forEach(item => {
        totalPrice = totalPrice + item.amount;
      });

      return totalPrice;
    },

    getOperationLiteral: operation => {
      switch (operation) {
        case PaymentConstants.OPERATION_PAYMENT_TOKENIZE:
          return translate('checkInList.detail.payment.operationPaymentTokenize');
        case PaymentConstants.OPERATION_PAYMENT:
          return translate('checkInList.detail.payment.operationPayment');
        default:
        case PaymentConstants.OPERATION_TOKENIZE:
          return translate('checkInList.detail.payment.operationTokenize');
      }
    },

    getTagColor: tag => {
      switch (String(tag)) {
        case PaymentConstants.PAYMENT_NOT_PAID:
        case PaymentConstants.WARRANTY_NOT_SAVED:
          return Colors.red;
        default:
        case PaymentConstants.PAYMENT_PAID:
        case PaymentConstants.WARRANTY_SAVED:
          return Colors.primaryGreen;
      }
    },
  };

  return { state, actions };
};

export default useCheckInPaymentHook;
