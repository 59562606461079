import React from 'react';
import { Modal } from 'antd';
import Space from 'Components/common/Space/Space';
import AppButton from 'Components/common/Forms/AppButton/AppButton';
import styles from './FeatModal.styles';

export default function FeatModal({
  visible,
  onCancel,
  title,
  subtitle,
  info,
  buttonText,
  buttonAction,
  popUpImage,
  ...props
}) {
  return (
    <Modal visible={visible} width={'50%'} footer={null} onCancel={onCancel}>
      <div style={styles.popUpContainer}>
        <div style={{ marginTop: 32 }}>
          <div style={styles.popupLeft}>{title}</div>
          <div style={styles.popupSubtitle}>{subtitle}</div>
          <Space height={16} />

          <div style={styles.popupText}>{info}</div>

          <div style={styles.buttonContainer}>
            <AppButton text={buttonText} action={buttonAction} />
          </div>
        </div>

        <img src={popUpImage} style={styles.popupImage} />
      </div>
    </Modal>
  );
}
