import axios from 'axios';
import apiConfig from '../api.config';
import constants from '../../constants';
import storage from '../../storage';

export default function saveBillingInformation({
  hotelId,
  companyLegalName,
  companyCommercialName,
  companyCountryCode,
  billingAddress,
  taxIdentificationNumber,
  billingContactName,
  billingContactEmail,
  billingPhoneNumber,
}) {
  const {
    billingHost,
    URIs: { saveBillingInformation },
  } = apiConfig;

  const uri = `${billingHost}${saveBillingInformation}`;

  const storedCredentials = JSON.parse(storage.get(constants.AUTH.USER_KEY));
  
  return axios
    .put(uri, {
      data: {
        hotelId: hotelId || 0,
        hotelReference: 'civitfun',
        companyLegalName,
        companyCommercialName,
        companyCountryCode,
        billingAddress,
        taxIdNumber: taxIdentificationNumber,
        billingContactName,
        billingContactEmail,
        billingPhoneNumber,
        accessToken: storedCredentials.accessToken,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => {
      return res.data;
    });
}
