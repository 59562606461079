export default {
  container: {
    display: 'flex',
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 24,
    paddingRight: 24,
  },
};
