module.exports = {
  en: 'The Online check-in is available for your reservation at [hotel_name]',
  es: 'Check-in online disponible para tu reserva en [hotel_name]',
  ca: 'El check-in online és disponible per a la reserva en [hotel_name]',
  de: 'Der Online Check-in ist für Ihre Reservierung unter [hotel_name] verfügbar.',
  fr: "L'enregistrement en ligne est disponible pour votre réservation à [hotel_name]",
  it: 'Il check-in online è disponibile per la tua prenotazione presso [hotel_name]',
  ja: 'The Online check-in is available for your reservation at [hotel_name]',
  nl: 'De online check-in is beschikbaar voor uw reservering bij [hotel_name]',
  pt: 'O Check-in Online está disponível para a sua reserva no [hotel_name]',
  pt_BR: 'O Check-in Online está disponível para a sua reserva no [hotel_name]',
  ru: 'Онлайн-регистрация доступна для вашего бронирования в [hotel_name]',
  zh: '您可以在 [hotel_name] 预订网上登记入住',
};
