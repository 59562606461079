import { CopyOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import AppButton from 'Components/common/Forms/AppButton/AppButton';
import Loading from 'Components/common/Loader/Loading';
import Colors from 'constants/Colors';
import React from 'react';
import { copyTextToClipboard } from 'utils/TextUtils';
import styles from './AutomaticConfigurationTest.styles';
import TestResult from './TestResult/TestResult';

export default function AutomaticConfigurationTest({
  icon,
  serviceName,
  buttonText,
  buttonAction,
  testResult,
  description,
  isTesting,
}) {
  const errorMsg = `${testResult?.message} \n${
    testResult?.data?.response?.stack ? testResult?.data?.response?.stack : ''
  }`;
  const log = testResult ? (testResult.success ? testResult?.message || 'Ok' : errorMsg) : null;

  return (
    <div style={styles.container}>
      <div style={{ ...styles.buttonContainer, width: 200 }}>
        <div style={styles.title}>{buttonText}</div>
        <div style={styles.description}>{description}</div>
        <AppButton text={buttonText} action={() => buttonAction(serviceName)} />
      </div>

      <div style={{ width: '100%', gap: 8, flexDirection: 'column' }}>
        <div style={{ width: '100%' }}>
          {isTesting ? (
            <Loading size={'small'} />
          ) : testResult != null ? (
            <div style={{ display: 'flex', flexDirection: 'row', gap: 16 }}>
              <TestResult success={testResult?.success} />
            </div>
          ) : null}
        </div>
        {testResult ? (
          <div style={styles.logBox}>
            {log}
            {testResult && !testResult?.success && (
              <div style={styles.copyButton}>
                <Button
                  type="primary"
                  shape="square"
                  ghost
                  style={{ color: Colors.superUserButton, borderColor: Colors.lightGray }}
                  icon={<CopyOutlined />}
                  onClick={() => copyTextToClipboard(log)}
                />
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
}
