import { notification } from 'antd';
import { AppContext } from 'App.context';
import { useContext, useEffect, useState } from 'react';
import { ApiContext } from 'utils/networking/Api.context';
import validator from 'validator';

export default function useBillingInfoHook() {
  const {
    appState,
    appActions: { getSelectedHotel, translate },
  } = useContext(AppContext);
  const { API } = useContext(ApiContext);

  const [billingInfoData, setBillingInfoData] = useState({
    companyLegalName: '',
    companyCommercialName: '',
    companyCountryCode: null,
    billingAddress: '',
    taxIdentificationNumber: '',
    billingContactName: '',
    billingContactEmail: [],
    billingPhoneNumber: '',
  });

  const [state, setState] = useState({
    isLoading: false,
    isEditing: false,
    isInfoComplete: false,
    formReady: false,
    ...billingInfoData,
    errors: [],
    selectedHotel: false,
    countryCodes: [],
  });

  useEffect(() => {
    getSelectedHotel()?.id && actions.loadBillingConfig(getSelectedHotel().id);
    getSelectedHotel()?.id && updateState({ selectedHotel: getSelectedHotel().id });
  }, [appState.selectedHotel]);

  useEffect(() => {
    actions.getBillingConfiguration();
  }, []);

  const actions = {
    loadBillingConfig: hotelId => {
      updateState({ isLoading: true, errors: [] });

      setBillingInfoData({
        companyLegalName: '',
        companyCommercialName: '',
        companyCountryCode: null,
        billingAddress: '',
        taxIdentificationNumber: '',
        billingContactName: '',
        billingContactEmail: [],
        billingPhoneNumber: '',
      });

      API.getBillingInformation(hotelId)
        .then(billingConfig => {
          let valid = true;
          for (const [key, _value] of Object.entries(billingInfoData)) {
            if (!billingConfig.data[key] || billingConfig.data[key] == '') {
              valid = false;
              break;
            }
          }

          const formData = { ...billingConfig.data };
          formData.billingContactEmail =
            (formData?.billingContactEmail && formData.billingContactEmail.split(';')) || [];

          updateState({
            isEditing: !valid ? true : false,
            isLoading: false,
            isInfoComplete: valid,
            formReady: valid,
            ...formData,
          });
        })
        .catch(err => {
          updateState({ isLoading: false });

          notification.error({
            message: err.message,
          });
        });
    },

    onSaveBillingInfo: () => {
      updateState({ isLoading: true });

      const valid = isValidForm(state);

      if (valid) {
        return API.saveBillingInformation({
          hotelId: getSelectedHotel().id,
          companyLegalName: state.companyLegalName,
          companyCommercialName: state.companyCommercialName,
          companyCountryCode: state.companyCountryCode,
          billingAddress: state.billingAddress,
          taxIdentificationNumber: state.taxIdentificationNumber,
          billingContactName: state.billingContactName,
          billingContactEmail: state.billingContactEmail.join(';'),
          billingPhoneNumber: state.billingPhoneNumber,
        })
          .then(result => {
            updateState({ isLoading: false });

            notification.success({
              message: translate('billingConfig.billingInfo.saveMessageOK'),
            });

            updateState({ isEditing: false, isLoading: false, isInfoComplete: true });

            return true;
          })
          .catch(err => {
            updateState({ isLoading: false });

            notification.error({
              message: err.message,
            });

            updateState({ isLoading: false });

            return false;
          });
      } else {
        updateState({ isLoading: false });

        notification.error({
          message: translate('billingConfig.billingInfo.formHasErrors'),
        });
      }
    },

    isFormFieldDisabled: () => {
      if (state.isLoading || !state.isEditing) {
        return true;
      }

      return false;
    },

    isFormEmpty: () => {
      let isEmpty = true;

      for (const [key, _value] of Object.entries(billingInfoData)) {
        if (state[key] != '' && billingInfoData[key] != '') {
          isEmpty = false;
          break;
        }
      }

      return isEmpty;
    },

    editCancel: () => {
      // Restore original info
      updateState({ isEditing: false, ...billingInfoData });
    },

    onEditBillingInfo: () => {
      // Backup form info
      const object = {};
      for (const [key, _value] of Object.entries(billingInfoData)) {
        object[key] = state[key];
      }
      setBillingInfoData(previousData => ({ ...previousData, ...object }));
      updateState({ isEditing: true });
    },

    setField: (fieldKey, value) => {
      updateState({ [fieldKey]: value });
    },

    onChangeField: (fieldKey, value) => {
      updateState({ [fieldKey]: value });
    },

    handleSearch: term => {
      const filteredOptions = state.countryCodes
        .filter(code =>
          translate('billingConfig.countryCodes.CODE_' + code)
            .toLowerCase()
            .includes(term.toLowerCase())
        )
        .map(code => ({
          value: code,
          label: translate('billingConfig.countryCodes.CODE_' + code),
        }));

      updateState({ countryCodes: filteredOptions });
    },

    getBillingContactEmailOptions: () => {
      const options = state.billingContactEmail;
      return options.map(item => {
        return { label: item, value: item };
      });
    },

    getCountryCodesOptions: () => {
      const countries = state.countryCodes.map(item => {
        return { value: item, label: translate('billingConfig.countryCodes.CODE_' + item) };
      });

      return countries.sort(
        (c, d) => c.label.toLowerCase().charCodeAt(0) - d.label.toLowerCase().charCodeAt(0)
      );
    },

    getBillingConfiguration: () => {
      API.getHotelBillingConfiguration()
        .then(response => {
          updateState({
            countryCodes: response.data.COUNTRY_CODES_VALUES,
          });
        })
        .catch(err => {
          notification.error({
            message: err?.message || 'Unknown error',
          });
        });
    },
  };

  function isValidFieldForm(key, value) {
    switch (key) {
      case 'companyLegalName':
        return !validator.isEmpty(value);
      case 'companyCommercialName':
        return !validator.isEmpty(value);
      case 'companyCountryCode':
        if (value) {
          if (!state.countryCodes.includes(value)) {
            return false;
          }
        }
        return !validator.isEmpty(value);
      case 'billingAddress':
      case 'billingContactName':
      case 'billingPhoneNumber':
        return !validator.isEmpty(value);
      case 'billingContactEmail':
        if (value.length == 0) {
          return false;
        }
        let isValid = true;
        value.forEach(element => {
          const res = !validator.isEmpty(element) && validator.isEmail(element);
          isValid *= res;
        });
        return isValid;
      //return !validator.isEmpty(value) && validator.isEmail(value) ? true : false;
      case 'taxIdentificationNumber':
        //FIXME: Missing tax validation
        return !validator.isEmpty(value);
      default:
        return false;
    }
  }

  function isValidForm(object) {
    let valid = true;
    let errors = [];

    if (!isValidFieldForm('companyLegalName', object.companyLegalName)) {
      valid = false;
      errors.push('companyLegalName');
    }

    if (!isValidFieldForm('companyCommercialName', object.companyCommercialName)) {
      valid = false;
      errors.push('companyCommercialName');
    }

    if (!isValidFieldForm('companyCountryCode', object.companyCountryCode)) {
      valid = false;
      errors.push('companyCountryCode');
    }

    if (!isValidFieldForm('billingAddress', object.billingAddress)) {
      valid = false;
      errors.push('billingAddress');
    }

    if (!isValidFieldForm('taxIdentificationNumber', object.taxIdentificationNumber)) {
      valid = false;
      errors.push('taxIdentificationNumber');
    }

    if (!isValidFieldForm('billingContactName', object.billingContactName)) {
      valid = false;
      errors.push('billingContactName');
    }

    if (!isValidFieldForm('billingContactEmail', object.billingContactEmail)) {
      valid = false;
      errors.push('billingContactEmail');
    }

    if (!isValidFieldForm('billingPhoneNumber', object.billingPhoneNumber)) {
      valid = false;
      errors.push('billingPhoneNumber');
    }

    updateState({ errors: errors });
    return valid;
  }

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}
