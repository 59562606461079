import { useContext, useEffect } from 'react';
import { useState } from 'react';
import { AppContext } from '../../../App.context';
import { ApiContext } from '../../../utils/networking/Api.context';
import CheckinOptions from './CheckinOptions';
import { notification } from 'antd';
import { value } from '../Guestlink/templates/body/empty-template';

export default function useCheckinConfigHook() {
  const { API } = useContext(ApiContext);
  const { appState, appActions } = useContext(AppContext);
  const { translate, getSelectedHotel } = appActions;

  const [state, setState] = useState({
    isLoading: false,
    selectedHotel: null,
    configOptions: null,
    tempValues: null,
    bookingSearchConfig: null,
    languages: null,
    selectedLanguages: [],
    disabledSelectors: [],
    bookingSummary: null,
    selectedBookingSummary: [],
  });

  const canDisable = {
    save_contract: 'send_contract',
    save_cardex: 'send_cardex'
  };

  useEffect(() => {
    actions.loadLanguages();
  }, []);

  useEffect(() => {
    actions.initView();
  }, [, appState.hotelList]);

  useEffect(() => {
    actions.setSelectedHotel(getSelectedHotel());
  }, [appState.selectedHotel]);

  const actions = {
    initView: () => {
      const searchConfigOptions = actions.getSearchConfigOptions();
      updateState({ searchConfigOptions });
    },

    loadCheckinConfig: hotelId => {
      if (!hotelId) return;
      updateState({ isLoading: true });
      API.checkinConfigRequest(hotelId)
        .then(res => {
          let disabledSelectors = [];
          let response = {}

          if (res?.bookingSummary && res?.selectedBookingSummary) {
            response = {
              bookingSummary: res?.bookingSummary,
              selectedBookingSummary: res?.selectedBookingSummary,
            };
            delete res?.bookingSummary;
            delete res?.selectedBookingSummary;
          }

          Object.entries(res).forEach(([key, value]) => {
            if (canDisable.hasOwnProperty(key)) {
              const correspondingValue = canDisable[key];
              if (value) {
                disabledSelectors = disabledSelectors.filter(item => item !== correspondingValue);
              } else {
                if (!disabledSelectors.includes(correspondingValue)) {
                  disabledSelectors.push(correspondingValue);
                }
              }
            }
          });

          updateState({
            ...response,
            isLoading: false,
            configOptions: res,
            tempValues: res,
            disabledSelectors,
          });
        })
        .catch(err => {
          updateState({ isLoading: false });
          notification.error({
            message: err?.response?.data?.message,
          });
        });
    },

    loadBookingSearchConfig: hotelId => {
      if (!hotelId) return;
      updateState({ isLoading: true, bookingSearchConfig: null });
      API.bookingSearchConfigRequest(hotelId)
        .then(res => {
          updateState({
            isLoading: false,
            bookingSearchConfig: res,
          });
        })
        .catch(err => {
          updateState({ isLoading: false });
          notification.error({
            message: err?.response?.data?.message,
          });
        });
    },

    setSelectedHotel: hotel => {
      if (!hotel) return;
      updateState({ selectedHotel: hotel });
      if (hotel && hotel?.key != 'null') {
        actions.loadCheckinConfig(hotel.key);
        actions.loadBookingSearchConfig(hotel.key);
        actions.loadAppearence(hotel.key);
      }
    },

    setValue: (key, value) => {
      const tempValues= {
        ...state.tempValues,
        [key]: value
      };

      let disabledSelectors = state.disabledSelectors;

      if (canDisable.hasOwnProperty(key)) {
        const correspondingValue = canDisable[key];
        if (value) {
          disabledSelectors = disabledSelectors.filter(item => item !== correspondingValue);
        } else {
          if (!disabledSelectors.includes(correspondingValue)) {
            disabledSelectors.push(correspondingValue);
          }
        }
      }

      updateState({ tempValues, disabledSelectors });
    },

    setBookingSearchValue: (key, value) => {
      const bookingSearch = JSON.parse(JSON.stringify(state.bookingSearchConfig));
      bookingSearch.forEach(element => {
        if (element.name == key) {
          element.state = value;
        }
      });

      updateState({ bookingSearchConfig: bookingSearch });
    },

    onSaveClicked: applyToAllHotels => {
      updateState({ isLoading: true });
      API.updateCheckinConfigRequest({
        hotelId: !applyToAllHotels ? state.selectedHotel?.key : null,
        values: state.tempValues,
      })
        .then(_ => {
          notification.success({
            message: translate('checkinConfig.success'),
          });
          updateState({ isLoading: false });
        })
        .catch(err => {
          notification.error({
            message: err?.response?.data?.message,
          });
          updateState({ isLoading: false });
        });
    },

    onSaveBookingSummary: applyToAllHotels => {
      updateState({ isLoading: true });

      let values = {};
      state.bookingSummary.forEach((item) => {
        values[item.key] = state.selectedBookingSummary.includes(item.id);
      })

      API.updateCheckinConfigRequest({
        hotelId: !applyToAllHotels ? state.selectedHotel?.key : null,
        values,
      })
        .then(_ => {
          notification.success({
            message: translate('checkinConfig.success'),
          });
          updateState({ isLoading: false });
        })
        .catch(err => {
          notification.error({
            message: err?.response?.data?.message,
          });
          updateState({ isLoading: false });
        });
    },

    onSaveBookingClicked: applyToAllHotels => {
      updateState({ isLoading: true });

      API.updateBookingSearchConfigRequest({
        hotelId: !applyToAllHotels ? state.selectedHotel?.key : null,
        values: state.bookingSearchConfig,
      })
        .then(_ => {
          notification.success({
            message: translate('checkinConfig.success'),
          });
          updateState({ isLoading: false });
        })
        .catch(err => {
          notification.error({
            message: err?.response?.data?.message,
          });
          updateState({ isLoading: false });
        });
    },

    getSelectorOptions: configOption => {
      const { configProperties, selectorOptions, guestLinkSelectorOptions } = CheckinOptions;
      let options = [];

      switch (String(configOption)) {
        case configProperties.allowedSince:
        case configProperties.allowedUntil:
        case configProperties.paperlessTimeout:
        case configProperties.paperlessFinalizeTimeout:
          return null;
        case configProperties.captureDocs:
          return [
            {
              key: selectorOptions.capture_docs_optional,
              literal: translate(`checkinConfig.options.capture_docs_optional`),
            },
            {
              key: selectorOptions.capture_docs_disabled,
              literal: translate(`checkinConfig.options.capture_docs_disabled`),
            },
            {
              key: selectorOptions.capture_docs_mandatory,
              literal: translate(`checkinConfig.options.capture_docs_mandatory`),
            },
          ];

        case configProperties.requiredDocuments:
          options = [
            selectorOptions.none,
            selectorOptions.one,
            selectorOptions.numAdults,
            selectorOptions.numGuests,
            selectorOptions.guestOver2,
            selectorOptions.guestOver10,
            selectorOptions.guestOver12,
            selectorOptions.guestOver14,
            selectorOptions.guestOver16,
          ];
          break;

        case configProperties.requiredSignatures:
        case configProperties.requiredCardexSignatures:
          options = [
            selectorOptions.none,
            selectorOptions.one,
            selectorOptions.oneInLastProcess,
            selectorOptions.holder,
            selectorOptions.numAdults,
            selectorOptions.guestOver14,
            selectorOptions.guestOver16,
          ];
          break;

        case configProperties.saveContract:
        case configProperties.saveCardex:
          options = [
            guestLinkSelectorOptions.yes,
            guestLinkSelectorOptions.no,
          ];
          break;

        case configProperties.sendCardex:
        case configProperties.sendContract:
          options = [
            guestLinkSelectorOptions.none,
            guestLinkSelectorOptions.holder,
            guestLinkSelectorOptions.numAdults,
            guestLinkSelectorOptions.numGuests,
          ];
          break;
      }

      return options.map(option => {
        const isCheckinHolder = String(configOption) == configProperties.requiredDocuments && option == '1';
        const item = {
          key: option,
          literal: isCheckinHolder ? translate(`checkinConfig.options.o_holder`) : translate(`checkinConfig.options.o_${option}`),
        };

        return item;
      });
    },

    getSearchConfigOptions: () => {
      const { selectorOptions } = CheckinOptions;
      return [
        {
          key: selectorOptions.active,
          literal: translate(`checkinConfig.options.o_${selectorOptions.active}`),
        },
        {
          key: selectorOptions.disabled,
          literal: translate(`checkinConfig.options.o_${selectorOptions.disabled}`),
        },
      ];
    },

    loadLanguages: () => {
      updateState({ isLoading: true });
      API.languagesRequest()
        .then(res => {
          updateState({ isLoading: false, languages: res });
        })
        .catch(err => {
          console.warn(err);
          updateState({ isLoading: false });
          notification.error({
            message: err?.response?.data?.message,
          });
        });
    },

    loadAppearence: hotelId => {
      if (!hotelId) return;
      updateState({ isLoading: true });
      API.appearenceRequest(hotelId)
        .then(res => {
          const selectedLanguages = res.languages.map(language => {
            return language.id;
          });

          updateState({
            selectedLanguages,
          });
        })
        .catch(err => {
          console.warn(err);
          updateState({ isLoading: false });
          notification.error({
            message: err?.response?.data?.message,
          });
        });
    },

    updateLanguages: applyToAllHotels => {
      updateState({ isLoading: true });
      API.updateAppearenceRequest({
        hotelId: !applyToAllHotels ? state?.selectedHotel?.key : null,
        languages: state.selectedLanguages,
      })
        .then(_ => {
          notification.success({
            message: translate('appearence.languageSuccess'),
          });
          updateState({ isLoading: false });
        })
        .catch(error => {
          notification.error({
            message: error?.response?.data?.message,
          });
          updateState({ isLoading: false });
        });
    },

    onCheckboxPress: ({ selectedCheckBox, id, type, isSelected}) => {
      const updateCheckBox = (type, checkedList) => {
        switch (type) {
          case 'bookingSummary':
            updateState({ selectedBookingSummary: checkedList });
            break;
          case 'languages':
            updateState({ selectedLanguages: checkedList });
            break;
          default:
            notification.error({
              message: 'Does not have any type',
            })
        }
      }

      if (isSelected) {
        const checkedList = selectedCheckBox;
        const index = checkedList.indexOf(id);
        if (index > -1) {
          checkedList.splice(index, 1);
        }
        updateCheckBox(type, checkedList);
      } else {
        const checkedList = selectedCheckBox;
        checkedList.push(id);
        updateCheckBox(type, checkedList);
      }
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}
