import React, { useContext } from 'react';
import styles from './MailTemplateDetail.styles.js';
import useMailTemplateDetailHook from './MailTemplateDetail.hook.js';
import DefaultLayout from 'Components/DefaultLayout/DefaultLayout.jsx';
import ContentBox from 'Components/common/ContentBox/ContentBox.jsx';
import { AppContext } from 'App.context.js';
import { useLocation } from 'react-router-dom';
import Loading from 'Components/common/Loader/Loading.jsx';
import CustomButton from 'Components/common/CustomButton/CustomButton.jsx';
import Colors from 'constants/Colors.js';
import TemplateEditor from './TemplateEditor/TemplateEditor.jsx';
import MailSubjectEditor from './MailSubjectEditor/MailSubjectEditor.jsx';
import AppSelector from 'Components/common/AppSelector/AppSelector.jsx';
import MailLanguageSelector from './MailLanguageSelector/MailLanguageSelector.jsx';
import Upload from 'antd/lib/upload/Upload';
import TestEmailModal from './TestEmailModal/TestEmailModal.jsx';
import Modal from 'antd/lib/modal/Modal';
import AppButton from 'Components/common/Forms/AppButton/AppButton.jsx';
import MailTemplateInput from './MailTemplateInput/MailTemplateInput.jsx';
import SaveButton from 'Components/common/SaveButton/SaveButton.jsx';
import { Checkbox } from 'antd';
import { getEmailScheduleCode } from 'utils/TextUtils.js';
import { getSubjectFromTypeAndLang, getTemplateFromTypeAndLang } from '../Guestlink.utils.js';
import POLICE_CONSTANTS from 'Components/Dashboard/PoliceReport/civitfun-police-report-utils/police.constants';

export default function MailTemplateDetail() {
  const {
    appActions: { translate },
  } = useContext(AppContext);

  const location = useLocation();
  const POLICE_REPORT_ENABLED = location?.state?.policeReportEnabled;
  const defaultSender = location?.state?.defaultSender;
  
  const { state, actions } = useMailTemplateDetailHook(
    location?.state?.templateId,
    POLICE_REPORT_ENABLED,
    defaultSender,
  );

  return (
    <DefaultLayout showHotelSelector={false} singleBox>
      <div style={styles.wrapper}>
        <div
          style={{
            ...styles.header,
            justifyContent: 'space-between',
          }}
        >
          <div style={styles.buttonsContainer}>
            <CustomButton
              text={translate('mailTemplates.back')}
              background={Colors.exportButton}
              color={Colors.white}
              onClick={() => {
                actions.onBackClicked();
              }}
            />
            {state.isLoading && <Loading size={'small'} />}
          </div>

          <SaveButton
            text={translate('save')}
            action={actions.checkIfTypeIsAlreadyInUse}
            showPopConfirm
            disabled={state.isLoading}
          />
        </div>
        <ContentBox singleBox noPadding>
          <div style={styles.container}>
            <div style={styles.leftColumn}>
              <MailTemplateInput
                disabled={state.isLoading}
                name={translate('mailTemplates.templateName')}
                value={state.template?.name}
                onChange={text => actions.onFieldChanged('name', text)}
              />
              <MailTemplateInput
                disabled={state.isLoading || !defaultSender}
                name={translate('mailTemplates.sendFrom')}
                value={state.template?.sendFrom}
                placeholder={translate('mailTemplates.sendFromHint')}
                onChange={text => actions.onFieldChanged('sendFrom', text)}
              />
              {actions.shouldShowRecipient() && (
                <MailTemplateInput
                  disabled={state.isLoading}
                  name={translate('mailTemplates.recipient')}
                  value={state.template?.recipient}
                  onChange={text => actions.onFieldChanged('recipient', text)}
                />
              )}
              <MailTemplateInput
                disabled={state.isLoading}
                name={translate('mailTemplates.ccList')}
                value={state.template?.ccList}
                placeholder={translate('mailTemplates.emailListHint')}
                onChange={text => actions.onFieldChanged('ccList', text)}
              />
              <MailTemplateInput
                disabled={state.isLoading}
                name={translate('mailTemplates.bccList')}
                value={state.template?.bccList}
                placeholder={translate('mailTemplates.emailListHint')}
                onChange={text => actions.onFieldChanged('bccList', text)}
              />
              <div style={styles.emailTypeContainer}>
                <div style={styles.emailTypeTitle}>{translate('mailTemplates.emailType')}</div>
                <div style={styles.emailTypeCheckboxContainer}>
                  <Checkbox
                    style={styles.checkbox}
                    checked={state.selectedCategories.includes('marketing')}
                    onChange={e => actions.onCategoryClicked('marketing', e.target.checked)}
                  >
                    {translate('mailTemplates.marketing')}
                  </Checkbox>
                  <Checkbox
                    style={styles.checkbox}
                    checked={state.selectedCategories.includes('transactional')}
                    onChange={e => actions.onCategoryClicked('transactional', e.target.checked)}
                  >
                    {translate('mailTemplates.transactional')}
                  </Checkbox>
                </div>
              </div>
              <AppSelector
                title={translate('mailTemplates.type')}
                value={
                  state.isLoading && !state.selectedScheduleType?.emailType
                    ? null
                    : translate(
                        `mailTemplates.templateType.${state.selectedScheduleType?.emailType}`
                      )
                }
                options={actions.getEmailTypeOptions()}
                onOptionSelected={option => actions.setSelectedType(option)}
                isLoading={state.isLoading}
              />
              <AppSelector
                title={translate('mailTemplates.scheduling')}
                value={
                  actions.getSchedulingOptions().length && state.selectedScheduleType.id
                    ? translate(
                        `mailTemplates.templateType.${getEmailScheduleCode(
                          state.selectedScheduleType
                        )}`
                      )
                    : null
                }
                options={actions.getSchedulingOptions()}
                onOptionSelected={option => actions.setSelectedSchedule(option)}
                isLoading={state.isLoading}
              />
              <MailLanguageSelector
                languages={state.availableLanguages}
                selectedLanguage={actions.getSelectedLanguage()}
                onLanguageSelected={actions.onLanguageSelected}
                isLoading={state.isLoading}
                applyToAllLanguages={state.applyToAllLanguages}
                setApplyToAllLanguages={actions.setApplyToAllLanguages}
              />
            </div>
            <div style={styles.rightColumn}>
              <MailSubjectEditor
                value={
                  actions.getSelectedEmailText()?.subject ||
                  getSubjectFromTypeAndLang(
                    state.selectedScheduleType?.emailType,
                    state.selectedScheduleType?.value,
                    actions.getSelectedLanguage()
                  )
                }
                onValueChange={actions.setSelectedEmailText}
              />
              <div style={{ height: 2 }}></div>
              <div>
                <div style={styles.editorHeader}>
                  <div style={styles.title}>{translate('mailTemplates.template')}</div>
                  <div style={styles.testButton}>
                    <AppButton
                      secondary
                      action={() => actions.setShowTestEmailModal(true)}
                      text={translate('mailTemplates.testButton')}
                    />
                  </div>
                </div>
                <TemplateEditor
                  value={
                    actions.getSelectedEmailText()?.value ||
                    getTemplateFromTypeAndLang(getEmailScheduleCode(state.selectedScheduleType), actions.getSelectedLanguage())
                  }
                  onValueChange={actions.setSelectedEmailText}
                  tagValues={state.hotelTagValues}
                />
                <div style={styles.buttonsContainer}>
                  <Upload
                    accept=".jpg,.jpeg,.png,.pdf"
                    listType={'text'}
                    showUploadList={{
                      showPreviewIcon: false,
                      showRemoveIcon: true,
                    }}
                    disabled={state.isLoading}
                    customRequest={actions.onFileAdded}
                    onRemove={actions.onRemoveFile}
                    fileList={actions.getSelectedLanguageAttachments()}
                    onPreview={() => {}}
                  >
                    {actions.getSelectedLanguageAttachments().length <= 0 && (
                      <AppButton secondary text={translate('mailTemplates.addAttachment')} />
                    )}
                  </Upload>
                </div>
              </div>
            </div>
          </div>
        </ContentBox>
      </div>

      <Modal
        visible={state.showSendTestEmailModal}
        onCancel={() => actions.setShowTestEmailModal(false)}
        okText={translate('mailTemplates.sendButton')}
        confirmLoading={state.isSendingTestEmail}
        onOk={() => actions.onSendTestEmail()}
      >
        <TestEmailModal
          setShowModal={actions.setShowTestEmailModal}
          setMail={actions.setTestEmailAddress}
        />
      </Modal>

      <style jsx>{`
        .rc-virtual-list-scrollbar-thumb {
          display: none;
        }
      `}</style>
    </DefaultLayout>
  );
}
