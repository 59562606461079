module.exports = {
  en: 'Thank you for your stay at [hotel_name]',
  es: 'Gracias por su estancia en [hotel_name]',
  ca: 'Gràcies per la seva estada a [hotel_name]',
  de: 'Vielen Dank für Ihren Aufenthalt im [hotel_name]',
  fr: 'Merci pour votre séjour à [hotel_name]',
  it: 'Grazie per il vostro soggiorno presso [hotel_name]',
  ja: '[hotel_name]に滞在いただきありがとうございます',
  nl: 'Bedankt voor uw verblijf in [hotel_name]',
  pt: 'Obrigado por sua estadia no [hotel_name]',
  pt_BR: 'Obrigado por sua estadia no [hotel_name]',
  ru: 'Спасибо за пребывание в [hotel_name]',
  zh: '感谢您入住 [hotel_name]',
};
