import { AppContext } from 'App.context';
import Colors from 'constants/Colors';
import React, { useContext } from 'react';
import styles from './TestResult.styles';

export default function TestResult({ success }) {
  const {
    appActions: { translate },
  } = useContext(AppContext);

  const color = success ? Colors.testOk : Colors.testKo;

  return (
    <div
      style={{
        ...styles.container,
        border: `1px solid ${color}`,
        color: color,
        fontWeight: 'bold',
      }}
    >
      {success ? translate('testOK') : translate('testKO')}
    </div>
  );
}
