module.exports = {
  value: `<p>
    Olá, [guest_first_name].&nbsp; Obrigado por escolher [hotel_name]. Nós realmente queremos receber você.
    Aqui estão os detalhes da sua reserva e os documentos em anexo: </p>
<p>
    Número de confirmação de reserva:&nbsp; [reference_number] </p>
<ul>
<li> Data de chegada:&nbsp; [entrance_date] </li>
<li>
    Data de partida:&nbsp; [departure_date] </li>
<li> Endereço:&nbsp; [address] </li>
</ul>
<p>
    Se você precisar fazer alterações ou solicitar assistência, ligue para [hotel_phone]&nbsp; ou envie um e-mail para [CIF_business_email].
    Esperamos recebê-lo em [CIF_city]&nbsp; em breve!&nbsp; </p>
<p> [hotel_name] </p>
<p> <img src = "[hotel_logo]" width = "200" height = "100"> <br> </p>`,
};
