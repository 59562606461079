module.exports = {
    value: `<p data-pm-slice="1 1 []">Dear [hotel_name],</p>
<p>
  I regret to inform you that the room upselling for one of your guests has been
  rejected. Below you will find the relevant details:
</p>
<ul class="ak-ul">
  <li>
    <p><strong>Upselling ID:</strong> [upsell_id]</p>
  </li>
  <li>
    <p><strong>Upselling Request Date:</strong> [upsell_request_date]</p>
  </li>
  <li>
    <p><strong>Upselling State:</strong> [Rechazado]</p>
  </li>
  <li>
    <p><strong>Booking Identifier:</strong> [booking_identifier]</p>
  </li>
  <li>
    <p>
      <strong>Reservation Start and End Date:</strong> [entrance_date] -
      [departure_date]
    </p>
  </li>
  <li>
    <p><strong>Original Room Type:</strong> [original_room_type]</p>
  </li>
  <li>
    <p><strong>Requested Room Type:</strong> [requested_room_type]</p>
  </li>
  <li>
    <p><strong>Upselling Price:</strong> [upsell_price]</p>
  </li>
  <li>
    <p><strong>Payment Status:</strong> [payment_status]</p>
  </li>
</ul>
<p>
  You can review all the details in the Civitfun panel
  (https://dashboard.civitfun.com/upsell).
</p>
<p>Best regards,</p>
<p>Team Civitfun</p>
`,
  };