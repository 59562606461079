import React, { useContext } from 'react';
import styles from './MailTemplates.styles.js';
import useMailTemplatesHook from './MailTemplates.hook.js';
import DefaultLayout from 'Components/DefaultLayout/DefaultLayout.jsx';
import { AppContext } from 'App.context.js';
import BaseTable from 'Components/common/BaseTable/BaseTable.jsx';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import CustomButton from 'Components/common/CustomButton/CustomButton.jsx';
import Colors from 'constants/Colors.js';
import { Button } from 'antd';
import { DeleteOutlined, EditFilled } from '@ant-design/icons';
import Loading from 'Components/common/Loader/Loading.jsx';
import { capitalize, getEmailScheduleCode } from 'utils/TextUtils.js';
import { getSubjectFromTypeAndLang } from '../Guestlink.utils.js';
import RequestSuscription from '../../../common/RequestSuscription/RequestSuscription.jsx';

export default function MailTemplates() {
  const {
    appActions: { translate },
  } = useContext(AppContext);
  const { state, actions } = useMailTemplatesHook();

  const columns = [
    {
      title: translate('mailTemplates.enabled'),
      dataIndex: 'id',
      key: 'enabled',
      align: 'center',
      sorter: false,
      render: (_, row) => {
        return (
          <Checkbox
            disabled={state.loadingEnabledTemplates}
            checked={row.enabled}
            onClick={() => actions.onEnableClicked(row)}
          />
        );
      },
    },
    {
      title: translate('mailTemplates.type'),
      dataIndex: 'type',
      key: 'type',
      searchable: false,
      sorter: false,
      render: (_, template) => {
        const label = translate(
          `mailTemplates.templateType.${actions.getTemplateTypeCode(template)}`
        );
        const prefix =
          template?.scheduleType?.category == 'marketing'
            ? `${capitalize(template?.scheduleType?.emailType)}: `
            : '';
        return <div>{`${prefix}${label}`}</div>;
      },
    },
    {
      title: translate('mailTemplates.templateName'),
      dataIndex: 'template',
      key: 'template',
      searchable: false,
      sorter: false,
    },
    {
      title: translate('mailTemplates.subject'),
      dataIndex: 'subject',
      key: 'subject',
      searchable: false,
      sorter: false,
      render: (_, row) => {
        const subject =
          row?.subject ||
          getSubjectFromTypeAndLang(row?.scheduleType?.emailType, row?.scheduleType?.value, 'en');
        return <div>{subject}</div>;
      },
    },
    {
      title: translate('salesChannels.actionCol'),
      dataIndex: 'icons',
      key: 'icons',
      searchable: false,
      sorter: false,
      render: (_, row) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'right',
              gap: 32,
            }}
          >
            <Button
              style={{ width: 32, height: 32, color: Colors.actionColor }}
              type="text"
              icon={<EditFilled />}
              onClick={() => actions.onOpenDetailClicked(row.id)}
            />
            <Button
              style={{ width: 32, height: 32, color: Colors.actionColor }}
              type="text"
              icon={<DeleteOutlined />}
              onClick={() => actions.onDeleteClicked(row.id)}
            />
          </div>
        );
      },
    },
  ];

  return (
    <DefaultLayout singleBox>
      {state.suscription ? (
        <>
      <div
        style={{
          ...styles.header,
          justifyContent: state.loadingEnabledTemplates ? 'space-between' : 'right',
        }}
      >
        {state.loadingEnabledTemplates && <Loading size={'small'} />}
        <CustomButton
          text={translate('mailTemplates.createTemplate')}
          background={Colors.exportButton}
          color={'white'}
          onClick={() => {
            actions.onCreateTemplateClicked();
          }}
        />
      </div>

      <div style={styles.tableContainer}>
        <div style={styles.table}>
          <BaseTable
            data={state.data}
            columns={columns}
            loading={state.isLoading}
            onRowClicked={actions.onRowClicked}
            localPagination
          />
        </div>
      </div>
      </>
      ) : (
        <RequestSuscription suscription="guestlink" />
      )}
    </DefaultLayout>
  );
}
