import axios from 'axios';
import apiConfig from 'utils/networking/api.config';

export default function doPmsConnectionCheck(hotelId) {
  const {
    host,
    URIs: { pmsConnectionCheck },
  } = apiConfig;

  const uri = `${host}${pmsConnectionCheck}`.replace(':hotelId', hotelId);

  return axios.post(uri).then(res => {
    return res.data;
  });
}
