import React, { useContext } from 'react';
import styles from './PMS.styles';
import { AppContext } from '../../../App.context';
import ContentBox from '../../common/ContentBox/ContentBox';
import InfoPill from '../../common/InfoPill/InfoPill';
import Loading from '../../common/Loader/Loading';
import DefaultLayout from '../../DefaultLayout/DefaultLayout';
import usePMSHook from './PMS.hook';
import { Table } from 'antd';
import PMSSelector from './PMSSelector/PMSSelector';
import PMSButton from './PMSButton/PMSButton';
import PMSConfig from './PMSConfig/PMSConfig';
import { EditOutlined } from '@ant-design/icons';
import Colors from 'constants/Colors';

export default function PMS() {
  const {
    appActions: { translate },
  } = useContext(AppContext);

  const { state, actions } = usePMSHook();
  const columns = [
    {
      title: translate('state'),
      key: 'name',
      dataIndex: 'name',
      width: '15%',
      align: 'center',
      render: (_, row) => (
        <InfoPill
          color={actions.getColorStateFromPMS(row)}
          backgroundColor={actions.getBackgroundColorStateFromPMS(row)}
          text={actions.getTextStateFromPMS(row)}
        />
      ),
    },
    {
      title: translate('hotel'),
      dataIndex: 'name',
      render: (_, row) => {
        return <div style={{ fontWeight: 500 }}>{row.name}</div>;
      },
    },
    {
      title: translate('pms'),
      dataIndex: 'id',
      width: '30%',
      align: 'left',
      render: (_, row) => {
        return <PMSSelector row={row} pmsList={state.pmsList} actions={actions} />;
      },
    },
    Table.EXPAND_COLUMN,
    {
      title: translate('salesChannels.actionCol'),
      dataIndex: 'id',
      width: '20%',
      align: 'center',
      render: (_, row) => {
        return actions.getPmsMode(row.id) !== 'form' ? (
          <PMSButton row={row} pmsList={state.pmsList} actions={actions} />
        ) : null;
      },
    },
  ];

  return (
    <DefaultLayout singleBox title={translate('layout.pms')} enableAllHotels={true}>
      <div style={{ paddingLeft: 32, paddingRight: 32 }}>
        <ContentBox noPadding singleBox>
          {state.isLoading ? (
            <div style={styles.loadingContainer}>
              <Loading />
            </div>
          ) : (
            <div>
              {state.hotels && state.pmsList && (
                <Table
                  columns={columns}
                  dataSource={state.hotels}
                  pagination={state.hotels?.length > 10}
                  expandable={{
                    rowExpandable: record => actions.isRowExpandable(record),
                    expandedRowRender: record => {
                      const selectedPms =
                        state.pmsList.find(pms => pms.id == actions.getSelectedPms(record.id)) ||
                        actions.getPmsFromName(record.pmsCheckin);
                      return (
                        <PMSConfig
                          rowData={record}
                          selectedPms={selectedPms}
                          setPmsCheckin={actions.setPmsCheckin}
                          pmsList={state.pmsList}
                        />
                      );
                    },
                    expandedRowKeys: [state.expandedRow],
                    onExpand: (expanded, record) => {
                      actions.onExpanded(record.id, expanded);
                    },
                    expandIcon: ({ expanded, onExpand, record }) =>
                      actions.isRowExpandable(record) ? (
                        expanded ? (
                          <ExpandIcon
                            bg={Colors.blueIntense}
                            onClick={onExpand}
                            record={record}
                            color={Colors.lightGreen}
                          />
                        ) : (
                          <ExpandIcon
                            bg={Colors.blueBoxColor}
                            onClick={onExpand}
                            record={record}
                            color={Colors.grayText}
                          />
                        )
                      ) : null,
                  }}
                />
              )}
            </div>
          )}
        </ContentBox>
      </div>
    </DefaultLayout>
  );
}

function ExpandIcon({ bg, onClick, record, color }) {
  return (
    <div
      style={{
        height: 36,
        width: 36,
        backgroundColor: bg,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 8,
        boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
      }}
      onClick={event => onClick(record, event)}
    >
      <EditOutlined style={{ color }} />
    </div>
  );
}
