import React, { useContext, useEffect, useState } from 'react';
import { Modal, notification } from 'antd';
import { AppContext } from 'App.context';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { ApiContext } from 'utils/networking/Api.context';
import { getEmailScheduleCode } from 'utils/TextUtils';

export default function useMailTemplatesHook() {
  const { confirm, info } = Modal;
  const history = useHistory();
  const { API } = useContext(ApiContext);

  const SUSCRIPTION = {
    ACTIVE: 'active',
    REQUESTED: 'requested',
  };

  const {
    appState,
    appActions: { translate, getSelectedHotel },
  } = useContext(AppContext);

  const [state, setState] = useState({
    isLoading: false,
    data: [],
    showMatchingTypeWarning: false,
    guestlinkSuscription: null /* null | active | requested */,
    loadingEnabledTemplates: false,
    policeReportSuscription: null,
    smtpConfig: null,
    suscription: null,
  });

  useEffect(() => {
    actions.getGuestlinkSuscriptionStatus();
    actions.loadTemplates();
  }, [, appState.selectedHotel]);

  const actions = {
    getGuestlinkSuscriptionStatus: () => {
      updateState({ isLoading: true });
      API.getGuestlinkSuscriptionStatus({ hotelId: getSelectedHotel()?.key }).then(res => {
        updateState({ suscription: res });
      });
    },
    
    loadTemplates: () => {
      const hotelId = getSelectedHotel().key;
      API.getMailTemplates({ hotelId })
        .then(mailTemplatesRes => {
          updateState({
            data: mailTemplatesRes,
            guestlinkSuscription: mailTemplatesRes.guestlinkSuscription,
            policeReportSuscription: mailTemplatesRes.policeReportSuscription,
          });
          API.getGuestlinkSmtpConfig({ hotelId }).then(smtpConfig => {
            updateState({
              isLoading: false,
              smtpConfig,
            });
          })
        })
        .catch(err => {
          updateState({ isLoading: false });
        });
    },

    onEnableClicked: selectedTemplate => {
      if (
        state.guestlinkSuscription === SUSCRIPTION.ACTIVE ||
        selectedTemplate?.scheduleType?.category?.includes('transactional')
      ) {
        if (selectedTemplate.enabled) {
          actions.setTemplateState({ templateId: selectedTemplate.id, enabled: false });
        } else {
          const sameTypeTemplates = state.data.items.filter(
            t => actions.getTemplateTypeCode(selectedTemplate) === actions.getTemplateTypeCode(t)
          );
          if (sameTypeTemplates.some(t => t.id !== selectedTemplate.id && t.enabled)) {
            actions.showTemplateTypeAlreadyEnabledWarning(selectedTemplate);
          } else {
            actions.setTemplateState({ templateId: selectedTemplate.id, enabled: true });
          }
        }
      } else if (state.guestlinkSuscription === SUSCRIPTION.REQUESTED) {
        actions.showSuscriptionRequestedError();
      } else {
        actions.showSuscriptionDisabledError();
      }
    },

    setTemplateState: ({ templateId, enabled }) => {
      updateState({ loadingEnabledTemplates: true });

      const oldState = JSON.parse(JSON.stringify(state.data));
      const copy = state.data;
      const selectedTemplate = copy.items.find(t => t.id === templateId);
      copy.items.forEach(template => {
        if (template.id === templateId) {
          template.enabled = enabled;
        } else if (
          enabled &&
          actions.getTemplateTypeCode(template) === actions.getTemplateTypeCode(selectedTemplate)
        ) {
          template.enabled = false;
        }
      });

      API.updateMailTemplateState({ hotelId: getSelectedHotel().key, templateId, enabled })
        .then(_ => {
          notification.success({
            message: translate('mailTemplates.successSetState'),
          });
          updateState({ data: copy, loadingEnabledTemplates: false });
        })
        .catch(_ => {
          notification.error({
            message: translate('mailTemplates.errorSetState'),
          });
          updateState({ data: oldState, loadingEnabledTemplates: false });
        });
    },

    getTemplateTypeCode: template => {
      return getEmailScheduleCode(template.scheduleType);
    },

    onCreateTemplateClicked: () => {
      history.push({
        pathname: `/guestlink/new-template`,
        state: {
          policeReportEnabled: state.policeReportSuscription === SUSCRIPTION.ACTIVE,
          defaultSender: state.smtpConfig?.default_sender || null,
        },
      });
    },

    onOpenDetailClicked: templateId => {
      history.push({
        pathname: `/guestlink/${templateId}`,
        state: {
          templateId,
          policeReportEnabled: state.policeReportSuscription === SUSCRIPTION.ACTIVE,
          defaultSender: state.smtpConfig?.default_sender || null,
        },
      });
    },

    onDeleteClicked: guestlinkId => {
      confirm({
        title: translate('mailTemplates.deleteGuestlinkItemTitle'),
        content: translate('mailTemplates.deleteGuestlinkItemDescription'),
        okText: translate('mailTemplates.deleteGuestlinkItemCTA'),
        okType: 'danger',
        onOk() {
          actions.deleteTemplate(guestlinkId);
        },
      });
    },

    deleteTemplate: guestlinkId => {
      updateState({ isLoading: true });
      API.deleteGuestlink({
        hotelId: getSelectedHotel().key,
        guestlinkId,
      })
        .then(_ => {
          actions.loadTemplates();
        })
        .catch(_ => {
          updateState({ isLoading: false });
        });
    },

    showSuscriptionDisabledError: () => {
      confirm({
        icon: null,
        title: translate('mailTemplates.suscriptionDisabledTitle'),
        content: translate('mailTemplates.suscriptionDisabled'),
        okText: translate('mailTemplates.suscriptionCTA'),
        onOk() {
          actions.requestGuestlinkFunctionality();
        },
      });
    },

    showSuscriptionRequestedError: () => {
      info({
        icon: <ExclamationCircleFilled />,
        title: translate('mailTemplates.suscriptionRequestedTitle'),
        content: translate('mailTemplates.suscriptionRequested'),
      });
    },

    showTemplateTypeAlreadyEnabledWarning: selectedTemplate => {
      confirm({
        icon: <ExclamationCircleFilled />,
        title: translate('mailTemplates.matchingTypeWarningTitle'),
        content: translate('mailTemplates.matchingTypeWarning'),
        onOk() {
          actions.setTemplateState({ templateId: selectedTemplate.id, enabled: true });
        },
      });
    },

    requestGuestlinkFunctionality: () => {
      updateState({ isLoading: true });
      API.requestFunctionalityContract({
        hotelId: getSelectedHotel().key,
        suscription: 'communication_tool',
        functionality: 'Guestlink',
      })
        .then(_ => {
          actions.sendRequestGuestlinkFunctionalityEmail();
        })
        .catch(_ => {
          updateState({ isLoading: false });
        });
    },

    sendRequestGuestlinkFunctionalityEmail: () => {
      updateState({ isLoading: true });
      API.sendRequestFuncionalityEmail({
        hotelId: getSelectedHotel().key,
        functionality: 'Guestlink',
      })
        .then(_ => {
          actions.loadTemplates();
          notification.success({ message: translate('mailTemplates.requestGuestlinkSuccess') });
          updateState({ isLoading: false });
        })
        .catch(_ => {
          updateState({ isLoading: false });
        });
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}
