import React from 'react';
import styles from './CheckinConfigSelector.styles';
import { InputNumber, Select } from 'antd';
import option from '../../Guestlink/templates/body/empty-template';

const { Option } = Select;

export default function CheckinConfigSelector({
  configOption,
  title,
  description,
  initialValue,
  setValue,
  options,
  value,
  disabled,
  hotel,
  disabledList,
}) {
  if (disabledList) {
    if (disabledList.includes(configOption)) {
      disabled = true;
    }
  }
  return (
    <div>
      <b style={styles.header}>{title}</b>
      <div style={styles.container}>
        {Array.isArray(options) ? (
          <Select
            key={`${configOption}_${hotel?.key}`}
            onChange={option => setValue(configOption, option)}
            value={initialValue}
            style={styles.input}
            bordered={false}
            disabled={disabled}
          >
            {options?.map((option, index) => {
              return (
                <Option key={index} value={option?.key}>
                  {option?.literal || option?.key}
                </Option>
              );
            })}
          </Select>
        ) : (
          <div style={styles.inputContainer}>
            <InputNumber
              style={styles.input}
              bordered={false}
              value={value || 0}
              min={0}
              onChange={value => {
                setValue(configOption, value);
              }}
            />
          </div>
        )}
      </div>
      {description && !description.includes('checkinConfig') && <div>{description}</div>}
    </div>
  );
}
