import { useState, useContext } from 'react';
import { ApiContext } from 'utils/networking/Api.context';
import { groupByKey } from 'utils/TextUtils';

export default function usePmsConfigHook(setPmsCheckin, rowData) {
  const [state, setState] = useState({
    isLoading: false,
    data: null,
    orderedConfig: {},
    isTesting: false,
    testResult: null,
    isTestingBooking: false,
    testBookingResult: null,
  });

  const { API } = useContext(ApiContext);

  const actions = {
    loadPmsConfig: async (pms, hotelId) => {
      if (pms.connectionType !== 'form') return;
      updateState({ isLoading: true });

      API.pmsConfigRequest({ pmsId: pms.id, hotelId })
        .then(res => {
          for (const field of res) {
            if (field.type === 'split') {
              field.splitValues = field.fields.map(() => '');

              if (field.value) {
                field.value.split(field.separator).forEach((subFieldValue, index) => {
                  if (subFieldValue) {
                    if ((pms.id === 21 || pms.id === 30) && field.key != 'pms_key') {
                      const splitIndex = subFieldValue.indexOf('=');
                      field.splitValues[index] =
                        splitIndex !== -1 ? subFieldValue.slice(splitIndex + 1) : subFieldValue;
                    } else {
                      field.splitValues[index] = subFieldValue;
                    }
                  }
                });
              }
            }
          }

          const orderedConfig = groupByKey(res, 'category');
          updateState({ data: res, isLoading: false, orderedConfig });
        })
        .catch(error => {
          updateState({ isLoading: false });
          console.error('Error loading PMS config:', error);
        });
    },

    onValueChanged: (key, text, splitValues, table) => {
      const dataCopy = state.data.map(field => {
        if (field.key === key && (!table || table === field.table)) {
          field.value = text;
          if (splitValues) {
            field.splitValues = splitValues;
          }
        }
        return field;
      });

      updateState({ data: dataCopy });
    },

    getFieldValue: (key, table) => {
      const index = state.data.findIndex(x => x.key === key && (table ? x.table === table : true));
      return state.data[index]?.value;
    },

    onSave: (pms, hotelId, currentPms) => {
      updateState({ isLoading: true });
      API.updatePmsConfigRequest({ hotelId, fields: state.data, pms })
        .then(_ => {
          if (!currentPms || currentPms === 'NoIntegration') {
            API.pmsConfigHotelRequest({ hotelId, pmsName: pms.name, pmsId: pms.id })
              .then(_ => {
                setPmsCheckin(hotelId, pms.name);
                actions.loadPmsConfig(pms, hotelId);
              })
              .catch(err => console.error(err));
          } else {
            actions.loadPmsConfig(pms, hotelId);
          }
        })
        .catch(_ => {
          updateState({ isLoading: false });
        });
    },

    doAutomaticTest: () => {
      updateState({ isTesting: true, testResult: null });
      API.doPmsConnectionCheck(rowData.id)
        .then(res => {
          updateState({ isTesting: false, testResult: res });
        })
        .catch(err => {
          console.warn(err);
          updateState({
            isTesting: false,
            testResult: {
              available: true,
              success: false,
              message: err,
              data: {},
            },
          });
        });
    },

    doBookingTest: bookingData => {
      updateState({ isTestingBooking: true, testBookingResult: null });
      API.doHealthCheckBooking(rowData.id, bookingData)
        .then(res => {
          updateState({ isTestingBooking: false, testBookingResult: res });
        })
        .catch(err => {
          console.warn(err);
          updateState({
            isTestingBooking: false,
            testBookingResult: {
              available: true,
              success: false,
              message: err,
              data: {},
            },
          });
        });
    },
  };

  function updateState(object) {
    setState(previousState => ({ ...previousState, ...object }));
  }

  return { state, actions };
}
