export default {
  tesa: {
    operatorName: 'Nombre operador',
    operatorPassword: 'Contraseña operador',
    endpoint: 'Endpoint',
  },
  generic: {},
  ttlock: {
    username: 'Cuenta',
    password: 'Contraseña',
    clientId: 'Client ID',
    clientSecret: 'Client Secret',
  },
  'assa abloy': {
    token: 'Token',
  },
  yacan: {
    user: 'Usuario',
    password: 'Contraseña',
  },
  akiles: {
    apiKey: 'API Key',
  },
  'salto space': {
    address: 'Dirección',
    port: 'Puerto',
  },
  'salto ks': {
    username: 'Usuario',
    password: 'Contraseña',
    siteId: 'Site ID',
  },
  'omnitec rent&pass': {
    username: 'Nombre de usuario',
    password: 'Contraseña',
    clientId: 'Client ID',
    clientSecret: 'Client Secret'
  },
};
